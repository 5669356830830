import React, { useEffect, useState, useContext } from "react";
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

// Section for Utilities and Context
import { AuthProvider, AuthContext } from "./utils/AuthContext";
import setAuthToken from './utils/setAuthToken';

// Section for Components
import Layout from "./pages/Layout";
// import Dashboard from "./pages/Dashboard";
import Directory from "./pages/Directory";
import CreateProfile from "./pages/CreateProfile";
import Profile from "./pages/Profile";
import PublicSquare from "./pages/PublicSquare";
import NotFound from "./pages/NotFound";
import PopoverInitializer from './components/PopoverInitializer';
import Landing from "./pages/Landing";
import PrivateRoute from "./components/PrivateRoute";
import ResetPasswordForm from "./pages/ResetPasswordForm";
import ConfirmRegistration from "./pages/ConfirmRegistration";

// MAIN APP FUNCTION
function AppRoutes() {
  const { auth } = useContext(AuthContext);

  return (
    <Routes>
      <Route path="/" element={auth.isAuthenticated ? <Navigate to="/public-square"/> : <Landing/>} />
      <Route path="/public-square" 
        element={<PrivateRoute><PublicSquare/></PrivateRoute>}
      />
      <Route path="/directory" 
        element={<PrivateRoute><Directory/></PrivateRoute>} 
      />
      <Route path="/profile/create" 
        element={<PrivateRoute><CreateProfile/></PrivateRoute>} 
      />
      <Route path="/profile/:id" 
        element={<PrivateRoute><Profile/></PrivateRoute>} 
      />
      <Route path="/reset-password/:token" element={<ResetPasswordForm />} />
      <Route path="/confirm-email/:token" element={<ConfirmRegistration />} />
      <Route path="*" element={<NotFound/>} />
    </Routes>
  );
}

export default function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Layout/>
        <AppRoutes/>
      </BrowserRouter>
      <PopoverInitializer />
    </AuthProvider>
  );
}