import React, { useState, useContext, useEffect } from "react";

// Section for Utilities and Context
import "./editProfileModal.css";
import API from "../utils/API";
import { AuthContext } from "../utils/AuthContext";

// Section for Components
import UpdatePasswordForm from "./UpdatePasswordForm";

export default function EditProfileModal(props) {
    const { auth, setAuth } = useContext(AuthContext);
    const [imageWrongFormat, setImageWrongFormat] = useState(false);
    const [imageWrongSize, setImageWrongSize] = useState(false);
    const [updatePassword, setUpdatePassword] = useState(false);
    const [isFormValid, setFormValid] = useState(false);

    const [selectedImage, setSelectedImage] = useState(props.profile_picture ? props.profile_picture : undefined);
    const [imageType, setImageType] = useState("");
    const [firstNameEntry, setFirstNameEntry] = useState(props.data.first_name);
    const [lastNameEntry, setLastNameEntry] = useState(props.data.last_name);
    const [workEmailEntry, setWorkEmailEntry] = useState(props.data.work_email);
    const [personalEmailEntry, setPersonalEmailEntry] = useState(props.data.personal_email ? props.data.personal_email : undefined);
    const [workPhoneEntry, setWorkPhoneEntry] = useState(undefined);
    const [mobilePhoneEntry, setMobilePhoneEntry] = useState(undefined);
    const [birthdayEntry, setBirthdayEntry] = useState(props.data.birthday ? props.data.birthday.slice(0, 10) : undefined);

    const [linkedInEntry, setLinkedInEntry] = useState(props.data.linkedin_url ? props.data.linkedin_url : undefined);
    const [facebookEntry, setFacebookEntry] = useState(props.data.facebook_url ? props.data.facebook_url : undefined);
    const [youtubeEntry, setYouTubeEntry] = useState(props.data.youtube_url ? props.data.youtube_url : undefined);
    const [threadsEntry, setThreadsEntry] = useState(props.data.threads_url ? props.data.threads_url : undefined);
    const [instagramEntry, setInstagramEntry] = useState(props.data.instagram_url ? props.data.instagram_url : undefined);
    const [tiktokEntry, setTikTokEntry] = useState(props.data.tiktok_url ? props.data.tiktok_url : undefined);

    const [preferredPronouns, setPreferredPronouns] = useState("");
    const pronouns = ["They/Them", "She/Her", "He/Him", ""];

    useEffect(() => {
        if (props.data.other_phone !== null) setWorkPhoneEntry(formatPhoneNumber(props.data.other_phone));
        if (props.data.mobile_phone  !== null) setMobilePhoneEntry(formatPhoneNumber(props.data.mobile_phone));

    }, [props.data]);

    // useEffect TO CHECK INPUTS TO ENABLE / DISABLE 'Add Job' BUTTON
    useEffect(() => {
        if (selectedImage && firstNameEntry && lastNameEntry && workEmailEntry && workPhoneEntry) {
            setFormValid(true);
        } else {
            setFormValid(false);
        }
    }, [selectedImage, firstNameEntry, lastNameEntry, workEmailEntry, workPhoneEntry]); 


    const handleClearUpdatePassword = () => {
        setUpdatePassword(false);
    };

    // console.log(selectedImage)
    const handleImageChange = async (event) => {
        const file = event.target.files[0];
    
        // Allowed image formats: jpg, jpeg, png, gif, webp
        const validFormats = ["image/jpg", "image/jpeg", "image/png"];
        if (!validFormats.includes(file.type)) {
            setImageWrongFormat(true); // Show error message for wrong format
            return; // Exit if wrong format
        } else {
            setImageWrongFormat(false); // Reset error message
        }
    
        setImageType(file.type); 
    
        // Resize image 
        const resizedImage = await resizeImage(file, 450, 600);
    
        // Check size limit (example: 1MB)
        const maxSizeBytes = 5 * 1024 * 1024; // 1MB limit
        if (resizedImage.size > maxSizeBytes) {
            setImageWrongSize(true); // Show error message for file too large
            return; // Exit if image is too large
        } else {
            setImageWrongSize(false); // Reset size error message
        }
    
        const reader = new FileReader();
        reader.onloadend = () => {
            setSelectedImage(reader.result);
        };
        reader.readAsDataURL(resizedImage);

        // Create FormData to send the image to the server
        const formData = new FormData();
        formData.append("profile_picture", resizedImage);  // Append the resized image
        formData.append("profile_id", props.data.profile_id);  // Append the profile_id
        formData.append("image_type", file.type);  // Append the image type

        console.log(formData)
    
        try {
            const response = await API.uploadProfilePicture(formData);
            if (response.status === 200) {
                // setSelectedImage(imageUrl);
                console.log("Image uploaded successfully", response.data);
            } else {
                console.error("Error uploading image");
            }
        } catch (error) {
            console.error("API upload error:", error);
        }
    };

    const resizeImage = (file, maxWidth, maxHeight) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const img = new Image();
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
    
                    let width = img.width;
                    let height = img.height;
    
                    // Maintain aspect ratio
                    if (width > height) {
                        if (width > maxWidth) {
                            height = Math.round((height * maxWidth) / width);
                            width = maxWidth;
                        }
                    } else {
                        if (height > maxHeight) {
                            width = Math.round((width * maxHeight) / height);
                            height = maxHeight;
                        }
                    }
    
                    canvas.width = width;
                    canvas.height = height;
                    ctx.drawImage(img, 0, 0, width, height);
    
                    // Handle different image formats (e.g., jpg, png)
                    canvas.toBlob(
                        (blob) => {
                            resolve(blob); // Return resized image blob
                        },
                        file.type === 'image/png' ? 'image/png' : 'image/jpeg', // Default to JPEG for compression
                        0.8 // Adjust compression quality for JPEG
                    );
                };
                img.onerror = reject;
                img.src = event.target.result;
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    };

    // HANDLER FOR ADDING JOB EXPERIENCE TO DATABASE
    const updateProfileInputs = async () => {
        // e.preventDefault();
        
        try {
            const data = {
                profile_id: props.data.profile_id,
                first_name: firstNameEntry,
                last_name: lastNameEntry, 
                work_email: workEmailEntry,
                personal_email: personalEmailEntry,
                mobile_phone: mobilePhoneEntry,
                other_phone: workPhoneEntry,
                birthday: birthdayEntry,
                linkedin_url: linkedInEntry,
                facebook_url: facebookEntry,
                youtube_url: youtubeEntry,
                threads_url: threadsEntry,
                instagram_url: instagramEntry, 
                tiktok_url: tiktokEntry,
                image_type: imageType,
                pronouns: preferredPronouns,
            }

            // if (!imageWrongFormat && !imageWrongSize){
            //     data.profile_picture = selectedImage

            // }
            
            const response = await API.updateProfileInfo(data);
            props.reRenderProfile();
            console.log("Profile info updated successfully:", response.data);
            clearModal();
        } catch(error) {
            console.error("Server request failed: ",error);
        }
    };

    // FORMAT PHONE NUMBER
    const formatPhoneNumber = (value) => {
        if (!value) return "";
        // Remove all non-numeric characters
        const cleaned = ('' + value).replace(/\D/g, '');

        // Check if the input is of correct length
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }

        return value;
    };

    const handleWorkPhoneChange = (e) => {
        const formattedPhoneNumber = formatPhoneNumber(e.target.value);
        setWorkPhoneEntry(formattedPhoneNumber);
    };

    const handleMobilePhoneChange = (e) => {
        const formattedPhoneNumber = formatPhoneNumber(e.target.value);
        setMobilePhoneEntry(formattedPhoneNumber);
    };

    const handlePronounsSelect = (pronouns) => {
        setPreferredPronouns(pronouns);
    }

    const clearModal = () => {
        const fileInput = document.getElementById('formFile');
        fileInput.value = '';

        setImageWrongFormat(false);
        setImageWrongSize(false);
        setFirstNameEntry(props.data.first_name);
        setLastNameEntry(props.data.last_name);
        setWorkEmailEntry(props.data.work_email);
        setPersonalEmailEntry(props.data.personal_email);
        setWorkPhoneEntry(props.data.other_phone);
        setMobilePhoneEntry(props.data.mobile_phone);
        setBirthdayEntry(props.data.birthday ? props.data.birthday.slice(0, 10) : "");    
        setLinkedInEntry(props.data.linkedin_url);
        setFacebookEntry(props.data.facebook_url);
        setYouTubeEntry(props.data.youtube_url);
        setThreadsEntry(props.data.threads_url);
        setInstagramEntry(props.data.instagram_url);
        setTikTokEntry(props.data.tiktok_url);

    };

    return (
        <> 
            <div className="modal fade edit-bio-modal" id="editPCIModal" tabIndex="-1" aria-labelledby="editProfileModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content edit-profile-modal-content position-absolute top-50 start-50 translate-middle-x">
                        <div className="modal-header edit-bio-modal-header">
                            <h5 className="modal-title modal-title display-6">Edit Profile Information</h5>
                        </div>
                        <div className="modal-body px-4">
                            <div className="edit-profile-body ">
                                
                                <div className="row profile-picture-div">
                                    <div className="col-md-3 px-4">
                                        <img className="picture-thumbnail rounded" src={selectedImage}/>
                                    </div>
                                    <div className="col-md-9 ps-md-4">
                                        <div className="input-group-sm mt-3 pt-1">
                                            <label htmlFor="formFile" className="form-label">Upload a profile picture</label>
                                            <span className="picture-type-limit ms-2">{"( .jpg, .jpeg, and .png only )"}</span>
                                            <input className="form-control" type="file" id="formFile" accept=".jpg, .jpeg, .png" onChange={handleImageChange} />
                                        </div>
                                        {imageWrongFormat === true && (
                                            <span className="image-upload-issue">Incorrect image format. Please select only .jpg or .jpeg.</span>
                                        )}
                                        {imageWrongSize === true && (
                                            <span className="image-upload-issue">Image is too large. Please resize or select a smaller image.</span>
                                        )}
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-6">
                                        <div className="input-group input-group-sm mb-2">
                                            <input
                                                className="form-control" 
                                                aria-label="With textarea"
                                                placeholder="First Name*"
                                                value={firstNameEntry}
                                                onChange={(e) => setFirstNameEntry(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-group input-group-sm mb-2">
                                            <input
                                                className="form-control" 
                                                aria-label="With textarea"
                                                placeholder="Last Name*"
                                                value={lastNameEntry}
                                                onChange={(e) => setLastNameEntry(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="input-group input-group-sm mb-2">
                                            <span className="input-group-text email-span"><i className="bi bi-envelope-at"/>&nbsp;Work</span>
                                            <input
                                                className="form-control" 
                                                aria-label="With textarea"
                                                placeholder="Work Email"
                                                value={workEmailEntry}
                                                onChange={(e) => setWorkEmailEntry(e.target.value)}
                                            />
                                        </div>

                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-group input-group-sm mb-2">
                                            <span className="input-group-text email-span"><i className="bi bi-envelope-at"/>&nbsp;Personal</span>
                                            <input
                                                className="form-control" 
                                                aria-label="With textarea"
                                                placeholder="Personal Email"
                                                value={personalEmailEntry}
                                                onChange={(e) => setPersonalEmailEntry(e.target.value)}
                                            />
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="input-group input-group-sm mb-2">
                                            <span className="input-group-text phone-span"><i className="bi bi-telephone"/>&nbsp;Office</span>
                                            <input
                                                className="form-control" 
                                                placeholder="916-555-0123"
                                                value={workPhoneEntry}
                                                onChange={handleWorkPhoneChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-group input-group-sm mb-2">
                                            <span className="input-group-text phone-span"><i className="bi bi-telephone"/>&nbsp;Mobile</span>
                                            <input
                                                className="form-control" 
                                                placeholder="916-555-0123"
                                                value={mobilePhoneEntry}
                                                onChange={handleMobilePhoneChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="input-group input-group-sm birthday-input mb-2">
                                            <span className="input-group-text bday-span"><i className="bi bi-cake"/>&nbsp;Birthday</span>
                                            <input 
                                                type="date" 
                                                className="form-control" 
                                                value={birthdayEntry}
                                                onChange={(e) => {setBirthdayEntry(e.target.value)}}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <select className="form-select form-select-sm mb-2" 
                                            aria-label="Small select example" 
                                            onChange={(e) => handlePronounsSelect(e.target.value)} 
                                            value={preferredPronouns}
                                        >
                                            <option value="" disabled>Pronouns</option>
                                            {pronouns.map((type) => (
                                                <option key={type} value={type} >{type}</option>
                                                
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="row mt-3 pb-0 mb-0">
                                    <h5 className="display-6 socials-title">Socials Links</h5>
                                    <div className="col-md-6">
                                        <div className="input-group input-group-sm mb-2">
                                            <span className="input-group-text pci-socials-edit pci-socials-linkedin"><i className="bi bi-linkedin"/></span>
                                            <input
                                                className="form-control" 
                                                aria-label="With textarea"
                                                placeholder="LinkedIn"
                                                value={linkedInEntry}
                                                onChange={(e) => setLinkedInEntry(e.target.value)}
                                            />
                                        </div>
                                        <div className="input-group input-group-sm mb-2">
                                            <span className="input-group-text pci-socials-facebook"><i className="bi bi-facebook"/></span>
                                            <input
                                                className="form-control" 
                                                aria-label="With textarea"
                                                placeholder="Facebook"
                                                value={facebookEntry}
                                                onChange={(e) => setFacebookEntry(e.target.value)}
                                            />
                                        </div>
                                        <div className="input-group input-group-sm mb-2">
                                            <span className="input-group-text pci-socials-youtube"><i className="bi bi-youtube"/></span>
                                            <input
                                                className="form-control" 
                                                aria-label="With textarea"
                                                placeholder="YouTube"
                                                value={youtubeEntry}
                                                onChange={(e) => setYouTubeEntry(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-group input-group-sm mb-2">
                                            <span className="input-group-text pci-socials-threads"><i className="bi bi-threads"/></span>
                                            <input
                                                className="form-control" 
                                                aria-label="With textarea"
                                                placeholder="Threads"
                                                value={threadsEntry}
                                                onChange={(e) => setThreadsEntry(e.target.value)}
                                            />
                                        </div>
                                        <div className="input-group input-group-sm mb-2">
                                            <span className="input-group-text pci-socials-instagram"><i className="bi bi-instagram"/></span>
                                            <input
                                                className="form-control" 
                                                aria-label="With textarea"
                                                placeholder="Instagram"
                                                value={instagramEntry}
                                                onChange={(e) => setInstagramEntry(e.target.value)}
                                            />
                                        </div>
                                        <div className="input-group input-group-sm mb-2">
                                            <span className="input-group-text pci-socials-tiktok"><i className="bi bi-tiktok"/></span>
                                            <input
                                                className="form-control" 
                                                aria-label="With textarea"
                                                placeholder="TikTok"
                                                value={tiktokEntry}
                                                onChange={(e) => setTikTokEntry(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <span className="update-password" onClick={() => setUpdatePassword(prevUpdatePassword => !prevUpdatePassword)}><i className="bi bi-shield-lock"/> Update Password</span>
                                    </div>
                                    {updatePassword && (
                                        <UpdatePasswordForm 
                                            clearUpdatePassword={handleClearUpdatePassword}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer pt-0">
                            <button type="button" className="btn modal-close" data-bs-dismiss="modal" alt="Button to close modal">Close</button>
                            <div className={isFormValid ? "" : "disabled-button"}>
                                <button 
                                    type="button" 
                                    className="btn modal-save" 
                                    alt="Button to save changes to bio"
                                    data-bs-dismiss="modal" 
                                    onClick={updateProfileInputs}
                                    disabled={!isFormValid}
                                >Save changes</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
        </>
    )
}