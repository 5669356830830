import React, {useState, useContext, useRef, useEffect } from "react";
import API from "../utils/API";
import ReCAPTCHA from "react-google-recaptcha";

// Section for Utilities and Context
import './registerModal.css';
import { AuthContext } from "../utils/AuthContext";

// Section for Components


// Local Functions


// MAIN FUNCTION
export default function RegisterModal() {
    const { auth, setAuth } = useContext(AuthContext);
    const recaptcha = useRef()
    const [registration, setRegistration] = useState({"first_name": "", "last_name": "", "password": "", "email": ""});
    const [passwordInput, setPasswordInput] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [upperVerify, setUpperVerify] = useState(false);
    const [lowerVerify, setLowerVerify] = useState(false);
    const [numberVerify, setNumberVerify] = useState(false);
    const [symbolVerify, setSymbolVerify] = useState(false);
    const [lengthVerify, setLengthVerify] = useState(false);
    const [betaEmails, setBetaEmails] = useState([]);
    const [approvedEmails, setApprovedEmails] = useState([]);
    const [approvedDomains, setApprovedDomains] = useState([]);
    const [betaConfirm, setBetaConfirm] = useState(false);
    const [loading, setLoading] = useState(false); 

    useEffect(() => {
        // GET SINGLE PROFILE INFO FROM DATABASE FOR PROFILE PAGE
        const getBetaEmails = async () => {        
            try {
                const data = await API.fetchBetaEmails(); 
                // console.log("Fetched Profile Data:", data.data);
                setBetaEmails(data.data);
            } catch (error) {
                console.log("Error fetching single profile:", error);
            }
        };

        const getApprovedEmails = async () => {        
            try {
                const data = await API.fetchApprovedEmails(); 
                // console.log("Fetched Profile Data:", data.data);
                setApprovedEmails(data.data);
            } catch (error) {
                console.log("Error fetching single profile:", error);
            }
        };

        const getApprovedDomains = async () => {        
            try {
                const data = await API.fetchApprovedDomains(); 
                // console.log("Fetched Profile Data:", data.data);
                setApprovedDomains(data.data);
            } catch (error) {
                console.log("Error fetching single profile:", error);
            }
        };

        getBetaEmails();
        getApprovedEmails();
        getApprovedDomains();
    }, []);  

    const submitRegistration = async () => {
        const captchaValue = recaptcha.current.getValue();
        if (!captchaValue) {
            alert('Please verify the reCAPTCHA!');
        } else {
            const data = await API.captchaVerify(JSON.stringify({ captchaValue }));
            if (data.data.success) {
                // Finalize registration and trigger email confirmation
                try {
                setLoading(true);
                const res = await API.signup(registration);
                console.log("Registration successful, email sent", res.data);
                resetStates();
                setLoading(false);
                alert(`Registration successful! Please check your email to confirm your account. \n\nSome email providers may filter the confirmation, so please check your junk folder if you do not see it within a few minutes.`);
                } catch (error) {
                console.error("Registration failed", error);
                alert("Registration failed");
                resetStates();
                }
            } else {
                alert('reCAPTCHA validation failed!');
            }
        }
    };

    const onChange = (e) => {
        const { name, value } = e.target;
        
        // Update the registration state
        setRegistration((prev) => ({ ...prev, [name]: value }));
    
        // Check for beta email match immediately when the email field changes
        if (name === "email") {
            const isBetaEmail = betaEmails.some((email) => email.beta_email === value);
            const isApprovedEmail = approvedEmails.some((email) => email.work_email === value);
            const isCaGovEmail = /@.*\.ca\.gov$/.test(value);

            // Extract domain from the email value
            const emailDomain = value.split('@')[1];
            const isApprovedDomain = approvedDomains.some((domain) => domain.approved_domain === emailDomain);

            setBetaConfirm(isBetaEmail || isCaGovEmail || isApprovedEmail || isApprovedDomain);
        }
    };

    const validatePassword = (e) => {
        handlePasswordInput(e);

        const uppercaseRegex = /[A-Z]/;
        const lowercaseRegex = /[a-z]/;
        const numberRegex = /[0-9]/;
        const symbolRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
        const minPasswordLength = 10;

        const hasUppercase = uppercaseRegex.test(e.target.value);
        const hasLowercase = lowercaseRegex.test(e.target.value);
        const hasNumber = numberRegex.test(e.target.value);
        const hasSymbol = symbolRegex.test(e.target.value);
        const isLengthValid = e.target.value.length >= minPasswordLength;

        setUpperVerify(hasUppercase);
        setLowerVerify(hasLowercase);
        setNumberVerify(hasNumber);
        setSymbolVerify(hasSymbol);
        setLengthVerify(isLengthValid);

        if (!hasUppercase || !hasLowercase || !hasNumber || !hasSymbol || !isLengthValid) {
            setPasswordError("Password does not meet criteria.");
        } else {
            setPasswordError("");
            onChange(e);
        }
    };

    const handlePasswordInput = (e) => {
        setPasswordInput(e.target.value);
    }

    const resetStates = () => {
        setRegistration({"first_name": "", "last_name": "", "password": "", "email": ""});
        setPasswordInput("");
        setUpperVerify(false);
        setLowerVerify(false);
        setNumberVerify(false);
        setSymbolVerify(false);
        setLengthVerify(false);
        setBetaConfirm(false);
    }

    return (
       <>
        <div className="modal fade" id="registerModal" tabIndex="-1" aria-labelledby="registerModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content register-modal-content position-absolute top-50 start-50 translate-middle-x">
                <div className="modal-header">
                    <h1 className="modal-title fs-4 display-6" id="registerModalLabel"><strong>Welcome!</strong> Please register an account.</h1>
                </div>
                <div className="modal-body">
                    <div className="register-message mb-3 mx-1">
                        <span>We're glad you have found <strong><i>'ello</i>Govna</strong>! There are plenty of platforms available to perform functions of government affairs and we hope to provide a service you find valuable to that work. 
                            <br/><br/>
                            If you have a <strong>ca.gov</strong> email or email listed on Cal-Access, please register a <u>free</u> account.
                            {/* <br/><br/>Please register a <u>free</u> account, look around a bit, stay a while, and let us know your thoughts.  */}
                        </span>
                    </div>
                    <hr/>
                    <h5 className="register-title fs-5 display-6"><strong>Register Today!</strong></h5>
                    <div className="row">
                        <div className="col-md-6 pe-md-2">
                            <div className="input-group py-1">
                                <span className="input-group-text register-span">First name</span>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    name="first_name"
                                    value={registration.first_name}
                                    onChange={(e) => onChange(e)}

                                />
                            </div> 
                        </div>
                        <div className="col-md-6 ps-md-2">
                            <div className="input-group py-1">
                                <span className="input-group-text register-span">Last name</span>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    name="last_name"
                                    value={registration.last_name}
                                    onChange={(e) => onChange(e)}

                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 pe-md-2">
                            <div className="input-group py-1">
                                <span className="input-group-text register-span">Email</span>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    name="email"
                                    value={registration.email}
                                    onChange={(e) => onChange(e)}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 ps-md-2">
                            <div className="input-group py-1">
                                <span className="input-group-text register-span">Password*</span>
                                <input 
                                    type="password" 
                                    className="form-control" 
                                    name="password"
                                    value={passwordInput}
                                    onChange={(e) => validatePassword(e)}
                                />
                                {/* {passwordError && <div className="password-error">{passwordError}</div>} */}
                                
                            </div>
                        </div>
                    </div>
                    
                    
                    <div className="input-group py-1">
                        <span className="password-requirements mt-2 text-center d-block">
                            * Required include at least:
                            <br/>
                            &nbsp;<i className={`bi ${lengthVerify === true ? 'bi-check2-circle' : 'bi-exclamation-circle'}`}/> 10 characters,
                            &nbsp;<i className={`bi ${upperVerify === true ? 'bi-check2-circle' : 'bi-exclamation-circle'}`}/> 1 uppercase,
                            &nbsp;<i className={`bi ${lowerVerify === true ? 'bi-check2-circle' : 'bi-exclamation-circle'}`}/> 1 lowercase,
                            &nbsp;<i className={`bi ${numberVerify === true ? 'bi-check2-circle' : 'bi-exclamation-circle'}`}/> 1 number, and
                            &nbsp;<i className={`bi ${symbolVerify === true ? 'bi-check2-circle' : 'bi-exclamation-circle'}`}/> 1 symbol
                        </span>
                    </div>
                </div>
                <div className="register-footer pt-0 mx-3">
                    <div className="row ">
                        <div className="d-flex justify-content-center">
                            <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY} />
                        </div>
                        
                    </div>
                    <div className="row mt-3 mb-2 justify-content-center">
                        <button type="button" className="btn register-button register-close mx-2" data-bs-dismiss="modal">Close</button>
                        <button type="button" 
                            className="btn register-button modal-save mx-2" 
                            data-bs-dismiss="modal" 
                            onClick={submitRegistration}
                            disabled={!betaConfirm}
                        >
                            {betaConfirm && (<>Register</>)}
                            {!betaConfirm && (<>Access Currently Unavailable</>)}
                        </button>                                           
                    </div>
                    <div className="input-group py-1 pt-0">
                        <span className="password-requirements mx-3 mt-0">
                            <br/><i>Note: Best practices encourage a different password for each account but we know that can be difficult. We recommend checking passwords against the <strong><a className="haveibeenpwned" href="https://haveibeenpwned.com/Passwords" target="_blank">haveibeenpwned database</a></strong> of known breaches to <strong>ensure you are <u>not</u> using a hacked password</strong>.</i></span>
                    </div>
                    
                </div>
                </div>
            </div>
        </div>
        {loading && (
            <div className="d-flex position-absolute top-50 start-50 translate-middle ">
                <div className="spinner-border loading-spinner" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        )}    
    </>
    )    
}