import React from "react";

// Section for Utilities and Context
import "./headlinecard.css";


export default function HeadlineCard(props) {

    return (
        <>  
            <a href={props.url} target="_blank" rel="noreferrer">          
            <div className="card headline-card mb-1">
                <div className="card-body">
                    <div>
                        <i className="bi bi-newspaper"/>  
                        <span className="headline-publication ms-1">{props.publication}</span>
                    </div>
                    <h5 className="headline-title pt-1 display-6" >{props.title}</h5>
                    <p className="headline-snippet mb-0"></p>
                    <p className="card-text headline-snippet bio-box-text">{props.snippet}</p>
                </div>
            </div>
            </a>  
        </>
    )
}