import React, { useState } from "react";

// Section for Utilities and Context
import './profileissues.css';
import data from "../utils/test.json";

// Section for Components
import ProfileIssuesFeed from "./ProfileIssuesFeed";

// Local Functions
function determineBillLink(item) {
    if (item.billLink != null) {
        return <a className="workload-item-title my-2" href={"https://leginfo.legislature.ca.gov/faces/billNavClient.xhtml?bill_id=" + item.sessionLink + item.sessionTypeNumber + item.billLink}  target="_blank">{item.bill} ({item.author}) {item.title}</a>;
    }
    else {
        return <span className="workload-item-title my-2" >{item.title}</span>;
    }
}

function ProfileWorkloadFeed() {
    return (
        <>
            <div id="issues-div" className="row profile-issues">
                <div className="col-11">
                        <h5 className="current-issues-header display-6">Current Workload</h5>
                    </div>
                    <div className="col-1">
                        <span><i class="bi bi-pencil-square workload-edit"/></span>
                    </div>
                </div>
                <div id="issues-div" className="row"> 
                {data.currentWorkload.map((item) => (
                    determineBillLink(item)
                    
                ))}
            </div>
        </>        
    )
};


// Main Function
export default function ProfileIssues(props) {
    const [activeFeed, setActiveFeed] = useState("issues");

    const handleFeedSwitch = (feedType) => {
        setActiveFeed(feedType);
    };

    return (
        <>
            <div className="profile-feed-container">
                {/* <div className="row profile-feed-buttons">
                    <button className={`feed-buttons issues-feed-button ${activeFeed === "issues" ? "active" : ""}`} onClick={() => handleFeedSwitch("issues")}>Issue Areas</button>
                    <button className={`feed-buttons workload-feed-button ${activeFeed === "workload" ? "active" : ""}`} onClick={() => handleFeedSwitch("workload")}>Current Workload</button>
                </div> */}
                
                {activeFeed === "issues" && <ProfileIssuesFeed issues={props.issues} user_id={props.user_id} profile_id={props.profile_id} reRenderProfile={props.reRenderProfile}/>}
                {activeFeed === "workload" && <ProfileWorkloadFeed />}

            </div>
           
        </>
    )
}