import React from "react";
import { useNavigate } from "react-router-dom";

import './directorycard.css';
import portrait1 from "../assets/portrait-1.png";
import portrait2 from "../assets/portrait-2.png";
import portrait3 from "../assets/portrait-3.png";
import portrait4 from "../assets/portrait-4.png";
import portrait5 from "../assets/portrait-5.png";
import portrait6 from "../assets/portrait-6.png";
import portrait7 from "../assets/portrait-7.png";

export default function DirectoryCard(props) {
    const navigate = useNavigate();

    const randomDefaultImage = () => {
        const defaultPicture = [portrait1, portrait2, portrait3, portrait4, portrait5, portrait6, portrait7];
        const randomIndex = Math.floor(Math.random() * defaultPicture.length);
        return defaultPicture[randomIndex];
    }

    const handleNavigate = (profile_id) => {
        navigate("/profile/" + profile_id);
    }

    return (
        <>
            <span key={props.profile_id} className="card directory-card m-2" onClick={() => handleNavigate(props.profile_id)}>
                <img src={props.profile_picture || randomDefaultImage()} 
                    className="card-img directory-card-image" 
                    alt={"Photo of " + props.firstName + " " + props.lastName}
                    />
                <div className="card-body px-2">
                    <h6 className="directory-card-name display-6"><strong>{props.firstName + " " + props.lastName}</strong></h6>
                    <p className="directory-card-title my-1"><i>{props.title}</i></p>
                    <p className="directory-card-org">{props.division || props.organization}</p>
                </div>
            </span>
           
           
        </>
    )
}