import React, { useContext, useState } from "react";

// Section for Utilities and Context
import './joblist.css';
import { AuthContext } from "../utils/AuthContext";

// Section for Components
import EditJobModal from "../components/EditJobModal";


// MAIN FUNCTION
export default function JobList(props) {
    const { auth, setAuth } = useContext(AuthContext);
    const [ positionData, setPositionData ] = useState([]);

    
    function companyDivision(position) {


        console.log(position.city)
        
        if (position.Division === null && position.city === null) {
            return <div className="position-company">{position.Organization.organization_name}
                        <div className="row">
                            <div className="col-11">
                                <div className="position-location">{position.Organization.city}, {position.Organization.state}</div>
                            </div>
                            <div className="col-1">
                                {auth.user.user_id === props.user_id && (
                                    <i className="bi bi-pencil-square edit-job-icon" alt="Edit Job Button" data-bs-toggle="modal" data-bs-target="#editJobModal" onClick={() => handleJobEditData(position)}/>
                                )} 
                                
                            </div>
                        </div>
                        
                    </div>
        }
        if (position.Division === null && position.city !== null) {
            return <div className="position-company">{position.Organization.organization_name}
                        <div className="row">
                            <div className="col-11">
                                <div className="position-location">{position.city}, {position.state}</div>
                            </div>
                            <div className="col-1">
                                {auth.user.user_id === props.user_id && (
                                    <i className="bi bi-pencil-square edit-job-icon" alt="Edit Job Button" data-bs-toggle="modal" data-bs-target="#editJobModal" onClick={() => handleJobEditData(position)}/>
                                )} 
                                
                            </div>
                        </div>
                        
                    </div>
        }
        if (position.Division !== null && position.Division.city !== null) {
            return <div className="position-company">{position.Organization.organization_name}, <i>{position.Division.division_name}</i>
                        <div className="row">
                            <div className="col-11">
                                <div className="position-location">{position.Division.city || position.city}, {position.Division.state || position.state}</div>
                            </div>
                            <div className="col-1">
                                {auth.user.user_id === props.user_id && (
                                    <i className="bi bi-pencil-square edit-job-icon" alt="Edit Job Button" data-bs-toggle="modal" data-bs-target="#editJobModal" onClick={() => handleJobEditData(position)}/>
                                )} 
                                
                            </div>
                        </div>  
                        
                    </div>
        }
        else {
            return <div className="position-company">{position.Organization.organization_name}, <i>{position.Division.division_name}</i>
                        <div className="row">
                            <div className="col-11">
                                <div className="position-location">{position.Organization.city}, {position.Organization.state}</div>
                            </div>
                            <div className="col-1">
                                {auth.user.user_id === props.user_id && (
                                    <i className="bi bi-pencil-square edit-job-icon" alt="Edit Job Button" data-bs-toggle="modal" data-bs-target="#editJobModal" onClick={() => handleJobEditData(position)}/>
                                )} 
                            </div>
                        </div>
                        
                    </div>
        }

    };

    function formatMonthYear(dateString) {
        const date = new Date(dateString);
        const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();
        return `${month} ${year}`;
    }

    function currentPosition(position) {
        const startDateFormatted = formatMonthYear(position.start_date);
        const endDateEntryFormatted = position.end_date ? formatMonthYear(position.end_date) : 'present';

        return <div className="col-6 position-dates">{startDateFormatted} - {endDateEntryFormatted}</div>
    };

    const handleJobEditData = (position) => {
        for (let i = 0; i < props.experience.length; i++) {
            if (props.experience[i].job_id === position.job_id){
                setPositionData(props.experience[i]);
                return;
            }
        }
    }

    const handleJobHistory = (props) => {
        if(props.experience.length > 0) {
            return (
                props.experience.map((position) => (
                    // Pass user data as props to UserCard component
                        <li className="list-group-item" key={position.job_id}>
                            <div className="row">
                                <div className="col-6 position-title"><b>{position.title}</b></div>
                                {currentPosition(position)}
                            </div>
                            <div className="row">
                                {companyDivision(position)}
                            </div>
                        </li>
                ))
            )
        }

        return <div className="py-2 ms-3">No job history available.</div>

    }

    return (
        <>
            <div className="card job-list-card">
                <div className="card-header job-list-header text-center display-6">Job History
                    {auth.user.user_id === props.user_id && (
                        <i className="bi bi-plus-circle add-job-icon" alt="Add Job Button" data-bs-toggle="modal" data-bs-target="#addJobModal"/>
                    )}
                </div>
                <div className="list-group list-group-flush job-list">
                    {handleJobHistory(props)}
                </div>
            </div>
            <EditJobModal
                data={positionData}
                reRenderProfile={props.reRenderProfile} 
            />
            
        </>
    )
}