import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

// Section for Utilities and Context
import API from "../utils/API";
import '../components/resetPassword.css';


// MAIN FUNCTION
export default function ResetPasswordForm() {
    const [newPassword, setNewPassword] = useState("");
    const [retypePassword, setRetypePassword] = useState("");
    const [upperVerify, setUpperVerify] = useState(false);
    const [lowerVerify, setLowerVerify] = useState(false);
    const [numberVerify, setNumberVerify] = useState(false);
    const [symbolVerify, setSymbolVerify] = useState(false);
    const [lengthVerify, setLengthVerify] = useState(false);
    const [passwordError, setPasswordError] = useState("");
    const { token } = useParams();
    const navigate = useNavigate();

    const handlePasswordReset = async () => {
        try {
            await API.resetPassword(token, newPassword);
            alert("Password has been reset");
            navigate("/");
        } catch (error) {
            console.error("Error resetting password:", error);
            alert("Error resetting password. Please try again later.");
        }
    };

    const validatePassword = (e) => {
        setNewPassword(e.target.value);

        const uppercaseRegex = /[A-Z]/;
        const lowercaseRegex = /[a-z]/;
        const numberRegex = /[0-9]/;
        const symbolRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
        const minPasswordLength = 10;

        const hasUppercase = uppercaseRegex.test(e.target.value);
        const hasLowercase = lowercaseRegex.test(e.target.value);
        const hasNumber = numberRegex.test(e.target.value);
        const hasSymbol = symbolRegex.test(e.target.value);
        const isLengthValid = e.target.value.length >= minPasswordLength;

        setUpperVerify(hasUppercase);
        setLowerVerify(hasLowercase);
        setNumberVerify(hasNumber);
        setSymbolVerify(hasSymbol);
        setLengthVerify(isLengthValid);

        if (!hasUppercase || !hasLowercase || !hasNumber || !hasSymbol || !isLengthValid) {
            setPasswordError("Password does not meet criteria.");
        } else {
            setPasswordError("");
            // onChange(e);
        }
    };

    return (
        <>
            <div className="reset-password-form p-5 rounded">
                <h1 className="display-6 reset-password-title">Reset Password</h1>
                <text className="password-requirements mx-2 mt-2">Required include at least: <br/><i class={`bi ${lengthVerify === true ? 'bi-check2-circle' : 'bi-exclamation-circle'}`}/> 10 characters, <i class={`bi ${upperVerify === true ? 'bi-check2-circle' : 'bi-exclamation-circle'}`}/> 1 uppercase, <i class={`bi ${lowerVerify === true ? 'bi-check2-circle' : 'bi-exclamation-circle'}`}/> 1 lowercase, <i class={`bi ${numberVerify === true ? 'bi-check2-circle' : 'bi-exclamation-circle'}`}/> 1 number, and <i class={`bi ${symbolVerify === true ? 'bi-check2-circle' : 'bi-exclamation-circle'}`}/> 1 symbol <br/></text>
                <hr/>
                <input 
                    className="form-control reset-password-inputs mb-2"
                    type="password" 
                    placeholder="New Password" 
                    value={newPassword}
                    onChange={(e) => validatePassword(e)}
                />
                <input 
                    className="form-control reset-password-inputs mb-2"
                    type="password" 
                    placeholder="Retype New Password" 
                    value={retypePassword}
                    onChange={(e) => setRetypePassword(e.target.value)}
                />
                {retypePassword !== "" && retypePassword !== newPassword && (
                    <div>
                        <text className="password-match">Passwords do not match</text>
                        
                    </div>
                    
                )}
                <button type="button" className="btn reset-password-button mt-3" onClick={handlePasswordReset}>Reset Password</button>
                
            </div>
        
        </>
    );
}