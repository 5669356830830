import React, {useState, useEffect, useRef} from "react";

// Section for Utilities and Context
import API from "../utils/API";
import './joblist.css';

// Section for Components


// Local Functions


// MAIN FUNCTION
export default function AddJobModal(props) {
    const [isCurrentPosition, setIsCurrentPosition] = useState(false);
    
    const [titleEntry, setTitleEntry] = useState("");
    const [addressEntry, setAddressEntry] = useState("");
    const [suiteEntry, setSuiteEntry] = useState("");
    const [cityEntry, setCityEntry] = useState("");
    const [stateEntry, setStateEntry] = useState("");
    const [zipCodeEntry, setZipCodeEntry] = useState(undefined);
    const [startDateEntry, setStartDateEntry] = useState(undefined);
    const [endDateEntry, setEndDateEntry] = useState(undefined);

    const [organizations, setOrganizations] = useState([]);
    const [organizationSearchTerm, setOrganizationSearchTerm] = useState("");
    const [selectedOrganizationId, setSelectedOrganizationId] = useState(null);
    const [organizationSelected, setOrganizationSelected] = useState(false);
    const [highlightedOrganizationIndex, setHighlightedOrganizationIndex] = useState(-1);
    const [organzationType, setOrganizationType] = useState("");

    const [addDivision, setAddDivision] = useState(false);
    const [divisions, setDivisions] = useState([]);
    const [divisionSearchTerm, setDivisionSearchTerm] = useState("");
    const [selectedDivisionId, setSelectedDivisionId] = useState(null);
    const [divisionSelected, setDivisionSelected] = useState(false);
    const [highlightedDivisionIndex, setHighlightedDivisionIndex] = useState(-1);
    const [divisionAddress, setDivisionAddress] = useState("");
    const [divisonSuite, setDivisionSuite] = useState("");
    const [divisionCity, setDivisionCity] = useState("");
    const [divisionState, setDivisionState] = useState("");
    const [divisionZipCode, setDivisionZipCode] = useState(undefined);

    const [isFormValid, setFormValid] = useState(false);
    const dropdownRef = useRef(null);
    const dropdownLength = 5;
    const groups = ["Associations", "Agencies", "Governor's Office", "Legislature", "Lobbyists", "Unions", "Other"];
    
    // console.log(selectedDivisionId)
    // API IMPORT AND HANDLERS OF ORGANIZATIONS DATA
    useEffect(() => {
        const fetchOrganizations = async () => {
            try {
                const response = await API.getOrganizations();
                const filteredOrganizations = response.data.filter(org =>
                    org.organization_name.toLowerCase().includes(organizationSearchTerm.toLowerCase())
                );
                setOrganizations(filteredOrganizations.slice(0,5));
            } catch (error) {
                console.error("Error fetching organizations:", error);
            }
        };

        if (organizationSearchTerm !== "") {
            fetchOrganizations();
        } else {
            setOrganizations([]);
        }
    }, [organizationSearchTerm, organizationSelected]);

    // useEffect TO CHECK INPUTS TO ENABLE / DISABLE 'Add Job' BUTTON
    useEffect(() => {
        if (titleEntry && selectedOrganizationId && addressEntry && cityEntry && stateEntry && zipCodeEntry && organzationType && startDateEntry) {
            if (isCurrentPosition || endDateEntry) {
                setFormValid(true);
            }
        } else {
            setFormValid(false);
        }
    }, [titleEntry, selectedOrganizationId, addressEntry, cityEntry, stateEntry, zipCodeEntry, organzationType, startDateEntry, isCurrentPosition, endDateEntry]); 
    
    const handleOrganizationClick = (orgName, orgId) => {
        setOrganizationSearchTerm(orgName);
        setSelectedOrganizationId(orgId);
        setOrganizationSelected(true);
        setHighlightedOrganizationIndex(-1);
    };

    // HANDLER FOR FETCHING DIVISIONS DATA
    const fetchDivisions = async () => {
        try {
            const response = await API.getDivisionsByOrgId(selectedOrganizationId);
            const filteredDivisions = response.data.filter(org =>
                org.division_name.toLowerCase().includes(divisionSearchTerm.toLowerCase())
            );
            setDivisions(filteredDivisions.slice(0,5));
        } catch (error) {
            console.error("Error fetching divisions:", error);
        }

    };
    
    const handleDivisionClick = (divName, divId) => {
        setDivisionSearchTerm(divName);
        setSelectedDivisionId(divId);
        setDivisionSelected(true);
        setHighlightedDivisionIndex(-1);
    };

    const handleGroupSelect = (orgGroup) => {
        setOrganizationType(orgGroup);
    }

    // DROPDOWN HEIGHT
    const calculateDropdownHeight = () => {
        const optionHeight = 35; 
        const maxHeight = optionHeight * dropdownLength;
        return `${maxHeight}px`;
    };

    // END DATE / CURRENT POSITION HANDLERS
    const handleCheckboxChange = (e) => {
        setIsCurrentPosition(e.target.checked);

        // Reset the end date when checkbox is checked
        if (e.target.checked) {
          setEndDateEntry("");
        }
    };
    
    const handleEndDateChange = (e) => {
        setEndDateEntry(e.target.value);
    };
    
    // Function to convert a string to proper case
    const toProperCase = (str) => {
        return str.toLowerCase().replace(/(?:^|\s)\w/g, (match) => match.toUpperCase());
    };

    // HANDLER FOR CLEARED ORGANIZATION INPUT
    const handleOrganizationInputChange = (e) => {
        // const properCaseValue = toProperCase();
        setOrganizationSearchTerm(e.target.value);

        if (organizationSelected) {
            setOrganizationSelected(false);
        }
    };
    
    // HANDLER FOR CLEARED DIVISION INPUT
    const handleDivisionInputChange = (e) => {
        const properCaseValue = toProperCase(e.target.value);
        setDivisionSearchTerm(properCaseValue);
        fetchDivisions();
        

        if (divisionSelected) {
            setDivisionSelected(false);
        }

        if (divisionSearchTerm === "") {
            setDivisions([]);
        } 
    };

    // HANDLER FOR NAVIGATING DROPDOWN MENU
    const handleOrganizationKeyDown = (e) => {
        if (e.key === "ArrowUp") {
            e.preventDefault();
            setHighlightedOrganizationIndex(prevIndex => Math.max(prevIndex - 1, 0));
        } else if (e.key === "ArrowDown") {
            e.preventDefault();
            setHighlightedOrganizationIndex(prevIndex => Math.min(prevIndex + 1, dropdownLength - 1));
        } else if (e.key === "Enter" && highlightedOrganizationIndex !== -1) {
            handleOrganizationClick(
                organizations[highlightedOrganizationIndex].organization_name,
                organizations[highlightedOrganizationIndex].organization_id
            );
        }
    };

    // HANDLER FOR NAVIGATING DROPDOWN MENU
    const handleDivisionKeyDown = (e) => {
        if (e.key === "ArrowUp") {
            e.preventDefault();
            setHighlightedDivisionIndex(prevIndex => Math.max(prevIndex - 1, 0));
        } else if (e.key === "ArrowDown") {
            e.preventDefault();
            setHighlightedDivisionIndex(prevIndex => Math.min(prevIndex + 1, dropdownLength - 1));
        } else if (e.key === "Enter" && highlightedDivisionIndex !== -1) {
            handleDivisionClick(
                divisions[highlightedDivisionIndex].division_name,
                divisions[highlightedDivisionIndex].division_id
            );
        }
    };

    // HANDLER FOR ADDING JOB EXPERIENCE TO DATABASE
    const addJobInputs = async () => {
        try {
            const data = {
                profile_id: props.profile_id,
                title: titleEntry,
                street_address: addressEntry,
                suite_number: suiteEntry,
                city: cityEntry,
                state: stateEntry,
                zip_code: zipCodeEntry,
                start_date: startDateEntry,
                current_job: isCurrentPosition
            }

            if(addDivision) {
                data.street_address = divisionAddress;
                data.suite_number = divisonSuite;
                data.city = divisionCity;
                data.state = divisionState;
                data.zip_code = divisionZipCode;
            } 

            if (selectedOrganizationId) {
                data.organization_id = selectedOrganizationId;
            } else {
                const orgData = {
                    organization_name: organizationSearchTerm,
                    group_type: organzationType
                }

                if(addDivision) {
                    orgData.street_address = divisionAddress;
                    orgData.city = divisionCity;
                    orgData.state = divisionState;
                    orgData.zip_code = divisionZipCode;
                } else if (addressEntry !== ""){
                    orgData.street_address = addressEntry;
                    orgData.city = cityEntry;
                    orgData.state = stateEntry;
                    orgData.zip_code = zipCodeEntry;
                }

                const orgResponse = await API.addOrganization(orgData);
                console.log("Organization added successfully:", orgResponse.data);

                const fetchResponse = await API.getOrganizations()

                for(let i = 0; i < fetchResponse.data.length; i++){
                    console.log("in org for loop")
                    if(fetchResponse.data[i].organization_name === organizationSearchTerm) {
                        console.log("for loop if statement")
                        setSelectedOrganizationId(fetchResponse.data[i].organization_id);
                        data.organization_id = fetchResponse.data[i].organization_id;
                        break;
                    }
                }
            }

            if (selectedDivisionId) {
                data.division_id = selectedDivisionId;

            } else if (!selectedDivisionId && divisionSearchTerm) {
                const divData = {
                    division_name: divisionSearchTerm,
                    street_address: divisionAddress,
                    city: divisionCity,
                    state: divisionState,
                    zip_code: divisionZipCode,
                    organization_id: data.organization_id
                }


                const divResponse = await API.addDivision(divData);
                console.log("Division added successfully:", divResponse.data);

                const fetchResponse = await API.getDivisionsByOrgId(divData.organization_id);

                for(let i = 0; i <= fetchResponse.data.length; i++){
                    if(fetchResponse.data[i].division_name === divData.division_name) {
                        console.log(fetchResponse.data[i].division_id)
                        setSelectedDivisionId(fetchResponse.data[i].division_id);
                        data.division_id = fetchResponse.data[i].division_id;
                        break;
                    }
                }
            }
            
            if (endDateEntry) {
                data.end_date = endDateEntry;
            }

            const response = await API.addJobExperience(data);
            props.reRenderProfile();
            console.log("Job experience added successfully:", response.data);
            clearAddJobModal();

        } catch(error) {
           console.error("Server request failed: ",error);
        }
        
    };

    const clearAddJobModal = () => {
        setTitleEntry("");
        setAddressEntry("");
        setSuiteEntry("");
        setCityEntry("");
        setStateEntry("");
        setZipCodeEntry("");
        setOrganizationSearchTerm("");
        setSelectedOrganizationId(null);
        setOrganizationSelected(false);
        setDivisionSearchTerm("");
        setSelectedDivisionId(null);
        setDivisionSelected(false);
        setStartDateEntry("");
        setEndDateEntry("");
        setIsCurrentPosition(false);
        setOrganizationType("");
        setDivisionAddress("");
        setDivisionSuite("");
        setDivisionCity("");
        setDivisionState("");
        setDivisionZipCode("");
        setAddDivision(false);
    };

    // MAIN RETURNED CODE
    return (
        <>
            {/* Modal to edit the content of the user's bio */}
            <div className="modal fade add-job-modal" id="addJobModal" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="addJobModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header add-job-modal-header">
                            <h5 className="modal-title add-job-modal-title display-6">Add Job Experience</h5>
                        </div>
                        <div className="modal-body">
                            <div className="input-group py-1">
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Title*"
                                    value={titleEntry}
                                    onChange={(e) => setTitleEntry(e.target.value)} 
                                    required
                                />
                            </div>
                            <div className="input-group py-1">
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Company*" 
                                    value={organizationSearchTerm} 
                                    onChange={handleOrganizationInputChange} 
                                    onKeyDown={handleOrganizationKeyDown}
                                    required
                                />
                            </div>
                            {!organizationSelected && organizations.length > 0 && (
                                <select className="form-select" size="5"
                                        style={{ maxHeight: calculateDropdownHeight(), overflowY: "auto" }}
                                        ref={dropdownRef} >
                                    {organizations.map((org, index) => (
                                        <option key={org.organization_id} 
                                        className={`dropdown-item add-job-option ${index === highlightedOrganizationIndex ? "active-option" : ""}`}
                                        onClick={() => handleOrganizationClick(org.organization_name, org.organization_id)}>{org.organization_name}<br/></option>
                                    ))}
                                </select>
                            )}
                            <div className="add-division-div">
                                <div className="add-division-line"> Work for a legislator, committee, or specific office? <i className="bi bi-arrow-right-short"/> <span className="add-division-select" onClick={() => setAddDivision(!addDivision)}>Add Office / Division</span></div>
                            </div>
                            
                            {addDivision && (
                                <div className="input-group py-1">
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="Office / Division" 
                                        value={divisionSearchTerm} 
                                        onChange={handleDivisionInputChange} 
                                        onKeyDown={handleDivisionKeyDown}
                                    />
                                </div>
                            )}
                            
                            {!divisionSelected && divisionSearchTerm && divisions.length > 0 && (
                                <select className="form-select" size="5" style={{ maxHeight: calculateDropdownHeight(), overflowY: "auto" }} ref={dropdownRef} >
                                    {divisions.map((div, index) => (
                                        <option key={div.division_id} 
                                        className={`dropdown-item add-job-option ${index === highlightedDivisionIndex ? "active-option" : ""}`}
                                        onClick={() => handleDivisionClick(div.division_name, div.division_id)}>{div.division_name}<br/></option>
                                    ))}
                                </select>
                            )}
                            <div className="input-group py-1">
                                <div className="row address-row">
                                    <div className="col-9 px-0 me-0">
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="Address*"
                                            value={addressEntry || divisionAddress}
                                            onChange={addDivision ? (e) => setDivisionAddress(toProperCase(e.target.value)) : (e) => setAddressEntry(toProperCase(e.target.value))}
                                        />
                                    </div>
                                    <div className="col-3 pe-0 me-0">
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="Suite #"
                                            value={suiteEntry || divisonSuite}
                                            onChange={addDivision ? (e) => setDivisionSuite(toProperCase(e.target.value)) : (e) => {setSuiteEntry(e.target.value)}}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="input-group py-1">
                                <div className="row">
                                    <div className="col-6 pe-0 me-0">
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="City*"
                                            value={cityEntry || divisionCity}
                                            onChange={addDivision ? (e) => setDivisionCity(toProperCase(e.target.value)) : (e) => {setCityEntry(toProperCase(e.target.value))}}
                                        />
                                    </div>
                                    <div className="col-3 pe-0 me-0">
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="State*"
                                            value={stateEntry || divisionState}
                                            onChange={addDivision ? (e) => setDivisionState(e.target.value.toUpperCase()) : (e) => {setStateEntry(e.target.value.toUpperCase())}}
                                        />
                                    </div>
                                    <div className="col-3">
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            aria-label="" 
                                            placeholder="ZIP*"
                                            value={zipCodeEntry || divisionZipCode}
                                            onChange={addDivision ? (e) => setDivisionZipCode(e.target.value) : (e) => setZipCodeEntry(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-sm-12 pe-0 me-0">
                                    <div className="input-group input-group-sm job-date-input py-1">
                                        <span className="input-group-text date-span">Start date*</span>
                                        <input 
                                            type="date" 
                                            className="form-control" 
                                            aria-label=""  
                                            value={startDateEntry}
                                            onChange={(e) => {setStartDateEntry(e.target.value)}}
                                            required
                                        />
                                    </div>
                                    <select className="form-select form-select-sm mt-2" 
                                        aria-label="Small select example" 
                                        onChange={(e) => handleGroupSelect(e.target.value)} 
                                        value={organzationType}
                                        required
                                    >
                                        <option value="" disabled>Select type of organization*</option>
                                        {groups.map((type) => (
                                            <option key={type} value={type} >{type}</option>
                                            
                                        ))}
                                    </select>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="input-group input-group-sm job-date-input py-1">
                                        <span className="input-group-text date-span">End date</span>
                                        <input 
                                            type="date" 
                                            className="form-control" 
                                            aria-label="" 
                                            value={endDateEntry} 
                                            onChange={handleEndDateChange} 
                                            disabled={isCurrentPosition}
                                        />
                                    </div>
                                    <div className="form-check form-check-reverse current-pos-check ms-2 pt-1">
                                        <input className="form-check-input" type="checkbox" id="flexCheckDefault" onChange={handleCheckboxChange}/>
                                        <label className="form-check-label" htmlFor="flexCheckDefault">
                                            Current Position
                                        </label>
                                    </div>
                                    
                                </div>
                            </div>
                            
                        </div>
                        <div className="modal-footer e">
                            <button 
                                type="button" 
                                className="btn modal-close" 
                                data-bs-dismiss="modal" 
                                alt="Button to close modal" 
                                onClick={clearAddJobModal}
                            >Close</button>
                            <div className={isFormValid ? "" : "disabled-button"}>
                                <button 
                                    type="button" 
                                    className="btn modal-save" 
                                    data-bs-dismiss="modal" 
                                    alt="Button to save changes to bio"
                                    onClick={addJobInputs}
                                    disabled={!isFormValid}
                                >Add Job</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}