import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

// Section for Utilities and Context
import API from "../utils/API";
import './layout.css';
import LoginModal from '../components/LoginModal';
import { AuthContext } from "../utils/AuthContext";


// MAIN FUNCTION
export default function ConfirmRegistration() {
    const { auth, setAuth } = useContext(AuthContext);
    const { token } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const confirmEmail = async () => {
            try {
                const response = await API.confirmRegistraionEmail(token);
                console.log(response)
                // Check if email confirmation was successful
                if (response.data.success) {
                    // Update auth context if needed
                    console.log(response.data.success);

                    setAuth({
                        ...auth,
                        user: { ...auth.user, email_confirmed: true },
                    });

                    // Optionally redirect or show a success message
                } else {
                    // Handle the failure (e.g., invalid or expired token)
                    console.error(response.data.message);
                    navigate('/error'); // Redirect to an error page
                }
            } catch (error) {
                console.error("Error confirming email:", error);
                navigate('/error'); // Handle the error, possibly redirect to an error page
            }
        };

        confirmEmail();
    }, []);

    return (
        <>
            <div className="confirm-registration-message p-5 rounded">
                <h1 className="confirm-registration-text display-6">Your email is confirmed!</h1>
                <br/>
                <h5 className="confirm-registration-text display-6">You can now 
                    <span className="landing-links" data-bs-toggle="modal" data-bs-target="#loginModal"> login</span> and enjoy <strong>'elloGovna</strong>. </h5>
                
                
            </div>
            <LoginModal/>
        
        </>
    );
}