import React, { useContext } from "react";
import { Navigate } from "react-router-dom";

// Section for Utilities and Context
import { AuthContext } from "../utils/AuthContext";


// component: Component, ...rest
export default function PrivateRoute({ children }) {
    const { auth } = useContext(AuthContext);

    if (!auth.isAuthenticated) {
        return <Navigate to="/" replace={true} />
    }

    return children;
};
