import React, { useState, useContext } from "react";

// Section for Utilities and Context
import "./biobox.css";
import API from "../utils/API";
import { AuthContext } from "../utils/AuthContext";

// Limit display of bio to 550 characters max
const BioBody = ({ body }) => {
    const MAX_CHARACTERS = 550;

    if (body === null) {
        return (
            <p className="card-text bio-box-text">No biography available.</p>
          );
    }
  
    // Logic to truncate body to maximum characters
    const truncatedBody = body.length > MAX_CHARACTERS ? `${body.substring(0, MAX_CHARACTERS)}...` : body;
  
    return (
      <p className="card-text bio-box-text">{truncatedBody}</p>
    );
};

export default function BioBox(props) {
    const { auth, setAuth } = useContext(AuthContext);
    const [newBio, setNewBio] = useState(props.bio);
    const [bioEditing, setBioEditing] = useState(false);

    // HANDLER FOR ADDING JOB EXPERIENCE TO DATABASE
    const submitUpdatedBio = async () => {
        // e.preventDefault();
        console.log(newBio);

        try {
            const data = {
                profile_id: props.profile_id,
                user_bio: newBio
            }
            
            const response = await API.updateBiography(data);
            props.reRenderProfile();
            console.log("Biography updated successfully:", response.data);
            // clearAddJobModal();

        } catch(error) {
           console.error("Server request failed: ",error);
        }
        
    };

    const handleEditBio = () => {
        setBioEditing(prevBioEditing => !prevBioEditing);

        if (bioEditing) {
            submitUpdatedBio();
        }
    };

    return (
        <>              
            <div className="card bio-box m-0">
                <div className="card-header bio-box-header text-center display-6">{props.firstName}'s Bio
                {auth.user.user_id === props.user_id && (
                    <i className={`bi bio-edit-icon ${bioEditing === false ? 'bi-pencil-square ' : 'bi-floppy2-fill'}`} 
                        alt="Edit Bio Button" 
                        onClick={() => handleEditBio()}                    
                    />
                )}
                </div>
                
                    {!bioEditing && (
                        <div className="card-body py-2">
                            <BioBody body={props.bio} />
                        </div>                        
                    )}
                    {bioEditing && (
                        <div className="card-body py-2 px-1">
                            <textarea
                                className="form-control bio-textarea px-2" 
                                aria-label="With textarea"
                                value={newBio} 
                                onChange={(e) => {setNewBio(e.target.value)}}
                            />
                        </div>                      
                        
                    )}
            </div>
        </>
    )
}