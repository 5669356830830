import React, { useState, useContext, useEffect, useRef }  from "react";

// Section for Utilities and Context
import './profileissues.css';
import API from "../utils/API";
import { AuthContext } from "../utils/AuthContext";

// Section for Components


// Local Functions
export default function ProfileIssuesFeed(props) {
    const { auth, setAuth } = useContext(AuthContext);
    const [profileId, setProfileId] = useState(null);
    const [issues, setIssues] = useState([]);
    const [editIssues, setEditIssues] = useState(false);
    const [issueSearchTerm, setIssueSearchTerm] = useState("");
    const [issueSelected, setIssueSelected] = useState(false);
    const [selectedIssueId, setSelectedIssueId] = useState(null);
    const [highlightedIssueIndex, setHighlightedIssueIndex] = useState(-1);
    const [redundantIssue, setRedundantIssue] = useState(false);
    const dropdownLength = 5;
    const dropdownRef = useRef(null);

    // API IMPORT AND HANDLERS OF ISSUE AREA DATA
    useEffect(() => {
        const fetchIssueAreas = async () => {
            try {
                const response = await API.getIssueAreas();
                const existingIssues = new Set(props.issues.map(issue => issue.IssueArea.issue_name.toLowerCase()));
                const filteredIssues = response.data.filter(issue =>
                    issue.issue_name.toLowerCase().includes(issueSearchTerm.toLowerCase()) &&
                    !existingIssues.has(issue.issue_name.toLowerCase())
                );
                setIssues(filteredIssues.slice(0,5));
            } catch (error) {
                console.error("Error fetching organizations:", error);
            }
        };

        if (issueSearchTerm !== "") {
            fetchIssueAreas();
        } else {
            setIssues([]);
        }
        
        setProfileId(props.profile_id);

    }, [issueSearchTerm, issueSelected]);


    const sortedIssues = props.issues.sort((a, b) => {
        if (a.IssueArea.issue_name < b.IssueArea.issue_name) return -1;
        if (a.IssueArea.issue_name > b.IssueArea.issue_name) return 1;
        return 0;
    });

    const checkCurrentIssues = (searchTerm) => {
        for (let i = 0; i < props.issues.length; i++) {
            if (searchTerm.toLowerCase() === props.issues[i].IssueArea.issue_name.toLowerCase()) {
                setRedundantIssue(true);
                setIssueSelected(false);
                return; 
            }
        }
        setRedundantIssue(false); // Reset if no match is found
    };

    // HANDLER FOR CLEARED ORGANIZATION INPUT
    const handleIssueInputChange = (e) => {
        const searchTerm = e.target.value;
        setIssueSearchTerm(searchTerm);
        checkCurrentIssues(searchTerm);

        if (issueSelected) {
            setIssueSelected(false);
        }
    };
    
    const handleIssueClick = (issueName, issueId) => {
        setIssueSearchTerm(issueName);
        setSelectedIssueId(issueId);
        setIssueSelected(true);
        setHighlightedIssueIndex(-1);
    };

    // DROPDOWN HEIGHT
    const calculateDropdownHeight = () => {
        const optionHeight = 35; 
        const maxHeight = optionHeight * dropdownLength;
        return `${maxHeight}px`;
    };

    // HANDLER FOR NAVIGATING DROPDOWN MENU
    const handleIssueKeyDown = (e) => {
        if (e.key === "ArrowUp") {
            e.preventDefault();
            setHighlightedIssueIndex(prevIndex => Math.max(prevIndex - 1, 0));
        } else if (e.key === "ArrowDown") {
            e.preventDefault();
            setHighlightedIssueIndex(prevIndex => Math.min(prevIndex + 1, dropdownLength - 1));
        } else if (e.key === "Enter" && highlightedIssueIndex !== -1) {
            handleIssueClick(
                issues[highlightedIssueIndex].issue_name,
                issues[highlightedIssueIndex].issue_id
            );
        }
    };

    // HANDLER FOR ADDING JOB EXPERIENCE TO DATABASE
    const addIssueArea = async () => {
        try {
            if (redundantIssue) {
                console.log("Cannot add a duplicate issue.")
            } else {    
                const data = {
                    profile_id: profileId
                }

                if (selectedIssueId) {
                    data.issue_id = selectedIssueId;
                } else {
                    const issueData = {
                        issue_name: issueSearchTerm
                    }

                    const orgResponse = await API.addIssueArea(issueData);
                    console.log("IssueArea added successfully:", orgResponse.data);

                    const fetchResponse = await API.getIssueAreas()

                    for(let i = 0; i < fetchResponse.data.length; i++){
                        console.log("in org for loop")
                        if(fetchResponse.data[i].issue_name === issueSearchTerm) {
                            console.log("for loop if statement")
                            setSelectedIssueId(fetchResponse.data[i].issue_id);
                            data.issue_id = fetchResponse.data[i].issue_id;
                            break;
                        }
                    }
                }

                const response = await API.addUserIssue(data);
                props.reRenderProfile();
                console.log("User issue added successfully:", response.data);
                clearIssueInputs();
            }

        } catch(error) {
           console.error("Server request failed: ",error);
        }
        
    };

    // HANDLER FOR DELETING A JOB RECORD
    const handleDeleteUserIssue = async (id) => {
        console.log("handle delete function: ", id)
        try {
            const data = {
                id: id
            }
            
            const response = await API.deleteUserIssue(data);
            props.reRenderProfile();
            console.log("Job experience deleted successfully:", response.data);

        } catch(error) {
           console.error("Server request failed: ",error);
        }
        
    };

    const clearIssueInputs = () => {
        setIssueSearchTerm("");
        setIssueSelected(false);
        setSelectedIssueId(null);
    };

    return (
        <>
            <div id="issues-div" className="row profile-issues">
                <div className="col-11">
                    <h5 className="current-issues-header display-6">Current Issue Areas</h5>
                </div>
                <div className="col-1">
                    {auth.user.user_id === props.user_id && (
                        <span>
                            <i className={`bi issues-edit ${!editIssues ? 'bi-pencil-square' : 'bi-check2-square'}`}
                                onClick={() => setEditIssues(prevEditIssues => !prevEditIssues)}
                            />
                        </span>
                    )}
                    
                </div>
            </div>
            {editIssues && (
                <div class="input-group input-group-sm mb-3">
                    <input type="text" 
                        className="form-control rounded-end " 
                        aria-label="Sizing example input" 
                        aria-describedby="inputGroup-sizing-sm"
                        placeholder="Enter a new issue area you're working on..."
                        value={issueSearchTerm}
                        onChange={handleIssueInputChange}
                        onKeyDown={handleIssueKeyDown}
                    />
                    <span><i class="bi bi-plus-circle add-issue-icon ms-2" onClick={addIssueArea}/></span>
                </div>
            )}
            {!issueSelected && issues.length > 0 && (
                <select className="form-select select-menu" size="5"
                        style={{ maxHeight: calculateDropdownHeight(), overflowY: "auto" }}
                        ref={dropdownRef} >
                    {issues.map((issue, index) => (
                        <option key={issue.issue_id} 
                        className={`dropdown-item add-job-option ${index === highlightedIssueIndex ? "active-option" : ""}`}
                        onClick={() => handleIssueClick(issue.issue_name, issue.issue_id)}>{issue.issue_name}<br/></option>
                    ))}
                </select>
            )}
            {redundantIssue && (
                <div className="redundant-issue-message mb-2 pt-0">You already cover this issue.</div>
            )}
            

            <div id="issues-div" className="row">            
                {props !== undefined && props.issues.length > 0 ? (
                    sortedIssues.map((issue) => (
                    <div key={issue.IssueArea.issue_id} className="row">
                        <div className="profile-single-issue">{issue.IssueArea.issue_name}
                            {editIssues && (
                                <span> <i className="bi bi-x-circle delete-issue" onClick={() => handleDeleteUserIssue(issue.id)}/></span>
                            )}
                        </div>
                    </div>
                    ))
                ) : (
                    <div className="row">
                    <div className="profile-single-issue">No assigned issues</div>
                    </div>
                )}
            </div>
            {/* <div id="issues-div" className="row profile-issues">
                <h5 className="current-issues-header display-6"><b>Committee Assignments</b></h5>
                {data.issueAreas.map((issue) => (
                    <div key={issue.committees.join('_')} className="row">
                        {issue.committees.map((item, index) => (
                            <div className="profile-single-issue">{item}</div>
                        ))}
                    </div>
                ))}            
            </div> */}
        </>
    )
};

