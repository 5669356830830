import React, {useState, useEffect, useContext} from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 

// Section for Utilities and Context
import API from "../utils/API";
import { AuthContext } from "../utils/AuthContext";
import './createpost.css';

// Section for Components


// Local Functions


// MAIN FUNCTION
export default function CreatePost(props) {
    const { auth, setAuth } = useContext(AuthContext);
    const [profileData, setProfileData] = useState([]);
    const [postTypesList, setPostTypesList] = useState([]);
    const [eventTypesList, setEventTypesList] = useState([]);

    const [postTitle, setPostTitle] = useState("");
    const [postBody, setPostBody] = useState("");
    const [postAuthor, setPostAuthor] = useState();
    const [postOrganization, setPostOrganization] = useState();
    const [postDivision, setPostDivision] = useState();
    const [postType, setPostType] = useState("");
    const [eventType, setEventType] = useState("");
    const [eventLocationEntry, setEventLocationEntry] = useState("");
    const [eventDateEntry, setEventDateEntry] = useState(undefined);
    const [startTimeEntry, setStartTimeEntry] = useState(undefined);
    const [endTimeEntry, setEndTimeEntry] = useState(undefined);

    const [missingFields, setMissingFields] = useState([]); 
    const [missingInfo, setMissingInfo] = useState(false);
    const [isFormValid, setFormValid] = useState(false);

    useEffect(() => {

        // GET POST TYPE INFO FROM DATABASE 
        const fetchProfileData = async () => {        
            try {
                const id = auth.user.user_id;
                const data = await API.getProfileDataForPost(id); 
                // console.log("Fetched Profile Info:", data.data);
                setProfileData(data.data);
            } catch (error) {
                console.log("Error fetching profile info:", error);
            }
        };

        // GET POST TYPE INFO FROM DATABASE 
        const fetchAllPostTypes = async () => {        
            try {
                const data = await API.getAllPostTypes(); 
                // console.log("Fetched Post Types List:", data.data);
                setPostTypesList(data.data);
            } catch (error) {
                console.log("Error fetching profile info:", error);
            }
        };

        // GET POST TYPE INFO FROM DATABASE 
        const fetchAllEventTypes = async () => {        
            try {
                const data = await API.getAllEventTypes(); 
                // console.log("Fetched Event Types List:", data.data);
                setEventTypesList(data.data);
            } catch (error) {
                console.log("Error fetching profile info:", error);
            }
        };
        
        fetchProfileData();
        fetchAllPostTypes();
        fetchAllEventTypes();

    }, [auth.user.user_id]); 

    useEffect(() => {
        if (profileData.length > 0) {
            setPostAuthor(profileData[0]?.profile_id)
            setPostOrganization(profileData[0]?.JobExperiences[0]?.Organization?.organization_id || null);
            setPostDivision(profileData[0]?.JobExperiences[0]?.Division?.division_id || null);
        }
    }, [profileData]);

    // useEffect to check inputs to enable / disable 'Save changes' button
    useEffect(() => {
        if (postTitle && postBody && postType) {
            if (postType === "9") {
                if (eventDateEntry && startTimeEntry && endTimeEntry) {
                    setFormValid(true);
                } else {
                    setFormValid(false);
                }
            } else {
                setFormValid(true);
            }
        } else {
            setFormValid(false);
        }
    }, [postTitle, postBody, postType, eventDateEntry, startTimeEntry, endTimeEntry]); 


    const handleGroupSelect = (type) => {
        setPostType(type);
    };


    const handleEventTypeSelect = (type) => {
        setEventType(type);
    };

    const handleSavePost = () => {
        let fields = [];

        if (!postTitle) fields.push("Post Title");
        if (!postBody) fields.push("Post Body");
        if (!postType) fields.push("Type of Post");
        
        if (postTitle && postBody && postType ) {
            handleAddPost();
        } else {
            setMissingFields(fields);
            setMissingInfo(true);
        }
    }

    const handleAddPost = async () => {
        try {
            const data = {
                post_title: postTitle,
                post_body: postBody,
                post_author: postAuthor,
                user_id: auth.user.user_id,
                organization_id: postOrganization,
                division_id: postDivision,
                post_type: postType
            }

            if (postType === "9") {
                data.event_location = eventLocationEntry;
                data.event_date = eventDateEntry;
                data.event_start = startTimeEntry;
                data.event_end = endTimeEntry;
                data.event_type = eventType;
            }

            const response = await API.addNewPost(data);
            clearAddPostStates();
            
            props.refresh();
            console.log("Job experience added successfully:", response.data);

        } catch(error) {
           console.error("Server request failed: ",error);
        }
        
    };

    const clearAddPostStates = () => {
        setProfileData([]);
        setPostTitle("");
        setPostBody("");
        setPostAuthor(null);
        setPostOrganization(null);
        setPostDivision(null);
        setPostType("");
        setMissingFields([]);
        setMissingInfo(false);
        // setCloseModal(false);
    };

    // MAIN RETURNED CODE
    return (
        <>             
            <div className="modal fade create-post-modal" id="createPostModal" tabIndex="-1" aria-labelledby="createPostModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content create-post-modal-content position-absolute top-50 start-50 translate-middle-x">
                        <div className="modal-header modal-header">
                            <h5 className="modal-title modal-title display-6">Create Post</h5>
                        </div>
                        <div className="modal-body ">
                            <div className="input-group">
                                <input 
                                    className="form-control mb-2" 
                                    type="text" 
                                    placeholder="Enter title" 
                                    value={postTitle}
                                    onChange={(e) => setPostTitle(e.target.value)} 
                                />
                            </div>
                            {/* <div className="input-group">
                                <textarea 
                                    className="form-control mb-2 post-body-input" 
                                    placeholder="Hear ye, hear ye, announce which thy has to decree..." 
                                    value={postBody}
                                    onChange={(e) => setPostBody(e.target.value)}
                                ></textarea>
                            </div> */}

                            {/* React Quill Editor */}
                            <div>
                                <ReactQuill
                                    className="form-control mb-2 p-1 post-body-input" 
                                    value={postBody}
                                    onChange={setPostBody}
                                    placeholder="Write your post..."
                                    modules={{
                                        toolbar: [
                                            ['bold', 'italic', 'underline'],       // Text formatting
                                            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                                            ['link'],                             // Link for URL/email
                                            ['clean']                             // Clear formatting
                                        ],
                                    }}
                                    formats={['header', 'bold', 'italic', 'underline', 'list', 'bullet', 'link']}
                                />
                            </div>
                            
                            <div className="input-group mb-2 post-type-input">                                
                                <select className="form-select form-select-sm" 
                                    onChange={(e) => handleGroupSelect(e.target.value)} 
                                    value={postType}
                                    required
                                >
                                    <option value="" disabled>Select type of post</option>
                                    {postTypesList.map((type) => (
                                        <option key={type.posttype_id} value={type.posttype_id} >{type.post_type}</option>
                                        
                                    ))}
                                </select>
                            </div>
                            {postType === "9" && (
                                <>
                                    <div className="row mb-2">
                                        <div className="col-12 col-md-8 pe-md-0">
                                            <div className="input-group input-group-sm event-location-input">
                                                <span className="input-group-text location-span">Event location</span>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    aria-label=""  
                                                    value={eventLocationEntry}
                                                    onChange={(e) => {setEventLocationEntry(e.target.value)}}
                                                    required
                                                />
                                            </div>
                                        </div>                               
                                    </div>
                                    <div className="row">
                                        <div className="col-8 col-md-5 pe-md-0 mb-2">
                                            <div className="input-group input-group-sm event-date-input">
                                                <span className="input-group-text date-span">Event date</span>
                                                <input 
                                                    type="date" 
                                                    className="form-control" 
                                                    aria-label=""  
                                                    value={eventDateEntry}
                                                    onChange={(e) => {setEventDateEntry(e.target.value)}}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-3 pe-md-0">
                                            <div className="input-group input-group-sm event-date-input">
                                                <span className="input-group-text time-span">Start</span>
                                                <input 
                                                    type="time" 
                                                    className="form-control" 
                                                    aria-label=""
                                                    value={startTimeEntry || "12:00"}
                                                    onChange={(e) => {setStartTimeEntry(e.target.value)}}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-3 pe-md-0">
                                        <div className="input-group input-group-sm event-date-input">
                                                <span className="input-group-text time-span">End</span>
                                                <input 
                                                    type="time" 
                                                    className="form-control" 
                                                    aria-label=""  
                                                    value={endTimeEntry || "13:00"}
                                                    onChange={(e) => {setEndTimeEntry(e.target.value)}}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-12 col-md-8 pe-md-0 post-type-input">
                                            <select className="form-select form-select-sm" 
                                                onChange={(e) => handleEventTypeSelect(e.target.value)} 
                                                value={eventType}
                                                required
                                            >
                                                <option value="" disabled>Select type of event</option>
                                                {eventTypesList.sort((a, b) => {
                                                        if (a.event_type === 'OTHER') return 1; // 'OTHER' goes to the end
                                                        if (b.event_type === 'OTHER') return -1; 
                                                        return a.event_type.localeCompare(b.event_type); // Alphabetical sorting
                                                    })
                                                    .map((type) => (
                                                    <option key={type.eventtype_id} value={type.eventtype_id} >{type.event_type}</option>
                                                    
                                                ))}
                                            </select>
                                        </div>                               
                                    </div>
                                </>
                            )}
                            

                        </div>
                        {missingInfo && (
                            <div className="row mx-2">
                                    <span className="missing-info-message">Please provide entries for the following required fields: {missingFields.join(", ")}</span>
                            </div>
                        )}
                        <div className="modal-footer">
                            <button type="button" 
                                className="btn modal-close" 
                                data-bs-dismiss="modal" 
                                alt="Button to close modal" 
                                onClick={clearAddPostStates}
                            >Close</button>
                            <div className={isFormValid ? "" : "disabled-button"}>
                                <button type="button" 
                                    className={`btn modal-save modal-save-new-post`}
                                    data-bs-dismiss="modal"
                                    alt="Button to save post" 
                                    onClick={handleSavePost} 
                                    disabled={!isFormValid}
                                >Save changes</button>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}