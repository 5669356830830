import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";

// Section for Utilities and Context
import API from "../utils/API";
import { AuthContext } from "../utils/AuthContext";
import "./resetPassword.css";



// MAIN FUNCTION
export default function UpdatePasswordForm(props) {
    const { auth, setAuth } = useContext(AuthContext);
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [retypePassword, setRetypePassword] = useState("");
    const [upperVerify, setUpperVerify] = useState(false);
    const [lowerVerify, setLowerVerify] = useState(false);
    const [numberVerify, setNumberVerify] = useState(false);
    const [symbolVerify, setSymbolVerify] = useState(false);
    const [lengthVerify, setLengthVerify] = useState(false);
    const [passwordError, setPasswordError] = useState("");

    // console.log(auth.token)

    const handlePasswordUpdate = async () => {
        try {
            const data = {
                token: auth.token,
                currentPassword: currentPassword,
                newPassword: newPassword

            }

            await API.updatePassword(data);
            alert("Password has been updated");
            setCurrentPassword("");
            setNewPassword("");
            setRetypePassword("");
            props.clearUpdatePassword();
        } catch (error) {
            console.error("Error updating password:", error);
            alert("Error updating password. Please try again.");
        }
    };

    const validatePassword = (e) => {
        setNewPassword(e.target.value);

        const uppercaseRegex = /[A-Z]/;
        const lowercaseRegex = /[a-z]/;
        const numberRegex = /[0-9]/;
        const symbolRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
        const minPasswordLength = 10;

        const hasUppercase = uppercaseRegex.test(e.target.value);
        const hasLowercase = lowercaseRegex.test(e.target.value);
        const hasNumber = numberRegex.test(e.target.value);
        const hasSymbol = symbolRegex.test(e.target.value);
        const isLengthValid = e.target.value.length >= minPasswordLength;

        setUpperVerify(hasUppercase);
        setLowerVerify(hasLowercase);
        setNumberVerify(hasNumber);
        setSymbolVerify(hasSymbol);
        setLengthVerify(isLengthValid);

        if (!hasUppercase || !hasLowercase || !hasNumber || !hasSymbol || !isLengthValid) {
            setPasswordError("Password does not meet criteria.");
        } else {
            setPasswordError("");
            // onChange(e);
        }
    };

    return (
        <>
            <h5 className="display-6 update-password-title mt-3 ">Update Password</h5>
            <div className="row">
                <div className="col-md-10">
                    <div className="input-group-sm">
                        <input 
                            className="form-control update-password-inputs mb-2"
                            type="password" 
                            placeholder="Current Password" 
                            value={currentPassword}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                        />
                        <input 
                            className="form-control update-password-inputs mb-2"
                            type="password" 
                            placeholder="New Password" 
                            value={newPassword}
                            onChange={(e) => validatePassword(e)}
                        />
                    </div>
                
                </div>
                <div className="col-md-2"></div>
            </div>
            <div className="row">
                <div className="col-md-10">
                    <div className="input-group-sm">
                        <input 
                            className="form-control update-password-inputs mb-2"
                            type="password" 
                            placeholder="Retype New Password" 
                            value={retypePassword}
                            onChange={(e) => setRetypePassword(e.target.value)}
                        />
                    </div>
                    
                </div>
                <div className="col-md-2">
                    <button type="button" className="btn update-password-button btn-sm" onClick={handlePasswordUpdate}>Update</button>                    
                </div>
            </div>
            
            {retypePassword !== "" && retypePassword !== newPassword && (
                <div>
                    <text className="password-match">Passwords do not match</text>
                    
                </div>
                
            )}
            <text className="password-requirements mx-2 mt-2 pb-1">Required include at least: <br/><i class={`bi ${lengthVerify === true ? 'bi-check2-circle' : 'bi-exclamation-circle'}`}/> 10 characters, <i class={`bi ${upperVerify === true ? 'bi-check2-circle' : 'bi-exclamation-circle'}`}/> 1 uppercase, <i class={`bi ${lowerVerify === true ? 'bi-check2-circle' : 'bi-exclamation-circle'}`}/> 1 lowercase, <i class={`bi ${numberVerify === true ? 'bi-check2-circle' : 'bi-exclamation-circle'}`}/> 1 number, and <i class={`bi ${symbolVerify === true ? 'bi-check2-circle' : 'bi-exclamation-circle'}`}/> 1 symbol <br/></text>       
        </>
    );
}