import React, {useState, useEffect, useContext} from "react";
import { useParams } from "react-router-dom";
import { Navigate, useNavigate } from "react-router-dom";

// Section for Utilities and Context
import "./claimProfile.css";
import API from "../utils/API";
import { AuthContext } from "../utils/AuthContext";

// Section for Components


// MAIN FUNCTION
export default function ClaimProfile(props) {
    const { auth, setAuth } = useContext(AuthContext);
    const [ alreadyClaimed, setAlreadyClaimed ] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            await checkUserIds();
        };

        fetchData();
    }, []);

    const checkUserIds = async () => {
        try {
            const res = await API.getProfileUserCheck();
            
            for(let i = 0; i < res.data.length; i++){

                if (res.data[i].user_id === auth.user.user_id){
                    setAlreadyClaimed(true);
                    return alreadyClaimed;
                }
            }

          } catch (error) {
            console.error(error);
        }
    }

    const handleConfirmClaim = async () => {
        try {
            const data = {
                profile_id: props.profile_id,
                user_id: auth.user.user_id
            }

            const response = await API.claimProfile(data);
            console.log("Profile claimed successfully:", response.data);
            navigate("/profile/" + props.profile_id);

        } catch(error) {
           console.error("Server request failed: ",error);
        }

    }

    // const handleKeyPress = (e) => {
    //         const modal = document.getElementById("loginModal"); 
    //         modal.setAttribute("data-bs-dismiss", "modal");
    //     if (e.key === "Enter") {
    //         submitLoginInfo();
    //     }
    // };

    // const onChange = (e) => {
    //     setCredentials({...credentials, [e.target.name]: e.target.value});
    // }
    // console.log("Auth user ID: ", auth.user_id);

    return (
       <>
            <div className="claim-div mt-4 pt-3 mb-0">
                <button className="claim-button" data-bs-toggle="modal" data-bs-target={`#claimProfileModal` + props.profile_id}> This is me! Click here to claim profile </button>
            </div>

            <div className="modal fade" id={`claimProfileModal` + props.profile_id} tabIndex="-1" aria-labelledby="claimProfileModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title claim-title fs-4 display-6" id="loginModalLabel"><strong>Claim Profile</strong> - Is this your info? </h1>
                    </div>
                    <div className="modal-body pb-0">
                        <p><strong>Please only claim the profile with information on you.</strong> <br/><br/>Users can only claim one profile to update and manage. We carefully built our database and want to ensure the information for all users is accurate and helpful across the platform. <br/><br/><u>If the information is yours</u>, please click <strong>Yes, This is Me</strong> below and we will reach out to confirm and ensure accuracy.</p>
                    </div>
                    <div className="modal-footer pt-0 mb-1">
                        <button type="button" className="btn claim-close" data-bs-dismiss="modal">Nevermind</button>
                        <button type="button" className="btn claim-save" data-bs-dismiss="modal" onClick={handleConfirmClaim}>Yes, This is Me</button>
                    </div>
                    </div>
                </div>
            </div>    
    </>
    )    
}