import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

// Section for Utilities and Context
import API from "../utils/API";
import './resetPassword.css';
import { AuthContext } from "../utils/AuthContext";

// Section for Components


// Local Functions


// MAIN FUNCTION
export default function RequestPasswordResetPassword() {
    const { auth, setAuth } = useContext(AuthContext);
    const [email, setEmail] = useState("");
    const navigate = useNavigate();

    const submitPasswordReset = async () => {
        try {
            await API.requestPasswordReset(email);
            alert('Password reset link has been sent to your email');

            const backdrop = document.querySelector('.modal-backdrop');
            if (backdrop) backdrop.remove();
        } catch (error) {
            console.error('Error sending password reset link:', error);
            alert('Error sending password reset link. Please try again later.');
        }

    }


    return (
       <>
        <div className="modal fade" id="passwordResetModal" tabIndex="-1" aria-labelledby="passwordResetModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title fs-4 display-6" id="passwordResetModalLabel"><strong>Submit email to reset password</strong></h1>
                </div>
                <div className="modal-body">
                    <div className="input-group py-1">
                        <span className="input-group-text login-span">Email</span>
                        <input 
                            type="text" 
                            className="form-control" 
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                </div>
                <div className="password-reset-footer pt-0">
                    <div className="row mt-3 mb-2 me-3 justify-content-center">
                        <button type="button" className="btn reset-password-button" data-bs-dismiss="modal" onClick={submitPasswordReset}>Reset Password</button>
                    </div>
                    
                </div>
                </div>
            </div>
        </div>    
    </>
    )    
}