import React, { useState, useContext, useEffect } from "react";

// Section for Utilities and Context
import "../pages/createProfile.css";
import API from "../utils/API";
import { AuthContext } from "../utils/AuthContext";

// Section for Components
import dapperDefault from "../assets/portrait-5.png";


export default function CreateProfileForm(props) {
    const { auth, setAuth } = useContext(AuthContext);
    const [imageWrongFormat, setImageWrongFormat] = useState(false);
    const [imageWrongSize, setImageWrongSize] = useState(false);
    const [missingInfo, setMissingInfo] = useState(false);

    const [selectedImage, setSelectedImage] = useState();
    const [imageType, setImageType] = useState("");
    const [firstNameEntry, setFirstNameEntry] = useState();
    const [lastNameEntry, setLastNameEntry] = useState();
    const [workEmailEntry, setWorkEmailEntry] = useState();
    const [personalEmailEntry, setPersonalEmailEntry] = useState();
    const [workPhoneEntry, setWorkPhoneEntry] = useState();
    const [mobilePhoneEntry, setMobilePhoneEntry] = useState();
    const [birthdayEntry, setBirthdayEntry] = useState();

    const [linkedInEntry, setLinkedInEntry] = useState();
    const [facebookEntry, setFacebookEntry] = useState();
    const [youtubeEntry, setYouTubeEntry] = useState();
    const [threadsEntry, setThreadsEntry] = useState();
    const [twitterEntry, setTwitterEntry] = useState();
    const [instagramEntry, setInstagramEntry] = useState();
    const [tiktokEntry, setTikTokEntry] = useState();

    const [preferredPronouns, setPreferredPronouns] = useState("");
    const [missingFields, setMissingFields] = useState([]); 
    const pronouns = ["They/Them", "She/Her", "He/Him", ""];

    useEffect(() => {
        setFirstNameEntry(auth.user.first_name);
        setLastNameEntry(auth.user.last_name);
        setPersonalEmailEntry(auth.user.email);
    }, [auth])


    const handleImageChange = async (event) => {
        const file = event.target.files[0];
    
        // Allowed image formats: jpg, jpeg, png, gif, webp
        const validFormats = ["image/jpg", "image/jpeg", "image/png"];
        if (!validFormats.includes(file.type)) {
            setImageWrongFormat(true); // Show error message for wrong format
            return; // Exit if wrong format
        } else {
            setImageWrongFormat(false); // Reset error message
        }
    
        setImageType(file.type); 
    
        // Resize image 
        const resizedImage = await resizeImage(file, 450, 600);
    
        // Check size limit (example: 1MB)
        const maxSizeBytes = 5 * 1024 * 1024; // 1MB limit
        if (resizedImage.size > maxSizeBytes) {
            setImageWrongSize(true); // Show error message for file too large
            return; // Exit if image is too large
        } else {
            setImageWrongSize(false); // Reset size error message
        }
    
        const reader = new FileReader();
        reader.onloadend = () => {
            setSelectedImage(reader.result);
        };
        reader.readAsDataURL(resizedImage);

        // Create FormData to send the image to the server
        const formData = new FormData();
        formData.append("profile_picture", resizedImage);  // Append the resized image
        formData.append("profile_id", auth.user.user_id);  // Append the profile_id
        formData.append("image_type", file.type);  // Append the image type

        console.log(formData)
    
        try {
            const response = await API.uploadProfilePicture(formData);
            if (response.status === 200) {
                // setSelectedImage(imageUrl);
                console.log("Image uploaded successfully", response.data);
            } else {
                console.error("Error uploading image");
            }
        } catch (error) {
            console.error("API upload error:", error);
        }
    };

    const resizeImage = (file, maxWidth, maxHeight) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const img = new Image();
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
    
                    let width = img.width;
                    let height = img.height;
    
                    // Maintain aspect ratio
                    if (width > height) {
                        if (width > maxWidth) {
                            height = Math.round((height * maxWidth) / width);
                            width = maxWidth;
                        }
                    } else {
                        if (height > maxHeight) {
                            width = Math.round((width * maxHeight) / height);
                            height = maxHeight;
                        }
                    }
    
                    canvas.width = width;
                    canvas.height = height;
                    ctx.drawImage(img, 0, 0, width, height);
    
                    // Handle different image formats (e.g., jpg, png)
                    canvas.toBlob(
                        (blob) => {
                            resolve(blob); // Return resized image blob
                        },
                        file.type === 'image/png' ? 'image/png' : 'image/jpeg', // Default to JPEG for compression
                        0.8 // Adjust compression quality for JPEG
                    );
                };
                img.onerror = reject;
                img.src = event.target.result;
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    };

    // FORMAT PHONE NUMBER
    const formatPhoneNumber = (value) => {
        if (!value) return "";
        // Remove all non-numeric characters
        const cleaned = ('' + value).replace(/\D/g, '');

        // Check if the input is of correct length
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }

        return value;
    };

    const handleWorkPhoneChange = (e) => {
        const formattedPhoneNumber = formatPhoneNumber(e.target.value);
        setWorkPhoneEntry(formattedPhoneNumber);
    };

    const handleMobilePhoneChange = (e) => {
        const formattedPhoneNumber = formatPhoneNumber(e.target.value);
        setMobilePhoneEntry(formattedPhoneNumber);
    };

    const handlePronounsSelect = (pronouns) => {
        setPreferredPronouns(pronouns);
    };

    // HANDLER FOR CLICKING NEXT AND TRIGGER PROFILE API
    const handleNextClick = () => {
        let fields = [];

        if (!selectedImage) fields.push("Profile Picture");
        if (!firstNameEntry) fields.push("First Name");
        if (!lastNameEntry) fields.push("Last Name");
        if (!workEmailEntry) fields.push("Work Email");
        if (!workPhoneEntry) fields.push("Office Phone");
        
        if (selectedImage && firstNameEntry && lastNameEntry && workEmailEntry && workPhoneEntry) {
            updateProfileInputs(); 
            props.handleNext(false);
        } else {
            setMissingFields(fields);
            setMissingInfo(true);
        }
    };

    // HANDLER FOR ADDING PROFILE TO DATABASE
    const updateProfileInputs = async () => {
        // e.preventDefault();
        
        try {
            const data = {
                first_name: firstNameEntry,
                last_name: lastNameEntry, 
                work_email: workEmailEntry,
                personal_email: personalEmailEntry,
                mobile_phone: mobilePhoneEntry,
                other_phone: workPhoneEntry,
                birthday: birthdayEntry,
                linkedin_url: linkedInEntry,
                facebook_url: facebookEntry,
                youtube_url: youtubeEntry,
                threads_url: threadsEntry, 
                twitter_url: twitterEntry, 
                instagram_url: instagramEntry, 
                tiktok_url: tiktokEntry,
                image_type: imageType,
                pronouns: preferredPronouns,
                user_id: auth.user.user_id
            }

            // if (!imageWrongFormat && !imageWrongSize){
            //     data.profile_picture = selectedImage

            // }
            
            const response = await API.createProfile(data);
            console.log("Profile created successfully:", response.data);
            props.setProfileId(response.data.profile_id);
            clearInputs();
        } catch(error) {
            console.error("Server request failed: ",error);
        }
    };

    const clearInputs = () => {
        const fileInput = document.getElementById('formFile');
        fileInput.value = '';

        setImageWrongFormat(false);
        setImageWrongSize(false);
        setMissingInfo(false);
        setFirstNameEntry("");
        setLastNameEntry("");
        setWorkEmailEntry("");
        setPersonalEmailEntry("");
        setWorkPhoneEntry("");
        setMobilePhoneEntry("");
        setBirthdayEntry("");    
        setLinkedInEntry("");
        setFacebookEntry("");
        setYouTubeEntry("");
        setThreadsEntry("");
        setTwitterEntry("");
        setInstagramEntry("");
        setTikTokEntry("");
    };

    return (
        <> 
            <div className="edit-profile-body ">                                
                <div className="row profile-picture-div">
                    <div className="col-md-3 px-md-4">
                        <img className="picture-thumbnail rounded" src={selectedImage || dapperDefault}/>
                    </div>
                    <div className="col-md-9 ps-md-4">
                        <div className="input-group-sm mt-3 pt-1">
                            <label htmlFor="formFile" className="form-label">Upload a profile picture</label>
                            <span className="picture-type-limit ms-2">{"( .jpg, .jpeg, and .png only )"}</span>
                            <input className="form-control" type="file" id="formFile" accept=".jpg, .jpeg, .png" onChange={handleImageChange} />
                        </div>
                        {imageWrongFormat === true && (
                            <span className="image-upload-issue">Incorrect image format. Please select only .jpg or .jpeg.</span>
                        )}
                        {imageWrongSize === true && (
                            <span className="image-upload-issue">Image is too large. Please resize or select a smaller image.</span>
                        )}
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-6">
                        <div className="input-group input-group-sm mb-2">
                            <input
                                className="form-control" 
                                aria-label="With textarea"
                                placeholder="First Name*"
                                value={firstNameEntry}
                                onChange={(e) => setFirstNameEntry(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input-group input-group-sm mb-2">
                            <input
                                className="form-control" 
                                aria-label="With textarea"
                                placeholder="Last Name*"
                                value={lastNameEntry}
                                onChange={(e) => setLastNameEntry(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="input-group input-group-sm mb-2">
                            <span className="input-group-text email-span"><i className="bi bi-envelope-at"/>&nbsp;Work</span>
                            <input
                                className="form-control" 
                                aria-label="With textarea"
                                placeholder="Work Email*"
                                value={workEmailEntry}
                                onChange={(e) => setWorkEmailEntry(e.target.value)}
                            />
                        </div>

                    </div>
                    <div className="col-md-6">
                        <div className="input-group input-group-sm mb-2">
                            <span className="input-group-text email-span"><i className="bi bi-envelope-at"/>&nbsp;Personal</span>
                            <input
                                className="form-control" 
                                aria-label="With textarea"
                                placeholder="Personal Email"
                                value={personalEmailEntry}
                                onChange={(e) => setPersonalEmailEntry(e.target.value)}
                            />
                        </div>
                        
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="input-group input-group-sm mb-2">
                            <span className="input-group-text phone-span"><i className="bi bi-telephone"/>&nbsp;Office</span>
                            <input
                                className="form-control" 
                                placeholder="916-555-0123"
                                value={workPhoneEntry}
                                onChange={handleWorkPhoneChange}
                            />
                        </div>
                        <div className="input-group input-group-sm birthday-input mb-2">
                            <span className="input-group-text bday-span"><i className="bi bi-cake"/>&nbsp;Birthday</span>
                            <input 
                                type="date" 
                                className="form-control" 
                                value={birthdayEntry}
                                onChange={(e) => {setBirthdayEntry(e.target.value)}}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input-group input-group-sm mb-2">
                            <span className="input-group-text phone-span"><i className="bi bi-telephone"/>&nbsp;Mobile</span>
                            <input
                                className="form-control" 
                                placeholder="916-555-0123"
                                value={mobilePhoneEntry}
                                onChange={handleMobilePhoneChange}
                            />
                        </div>
                        <select className="form-select form-select-sm mt-2" 
                            aria-label="Small select example" 
                            onChange={(e) => handlePronounsSelect(e.target.value)} 
                            value={preferredPronouns}
                        >
                            <option value="" disabled>Pronouns</option>
                            {pronouns.map((type) => (
                                <option key={type} value={type} >{type}</option>
                                
                            ))}
                        </select>
                    </div>
                </div>
                <div className="row mt-3 pb-0 mb-0">
                    <h5 className="display-6 socials-title">Socials Links</h5>
                    <div className="col-md-6">
                        <div className="input-group input-group-sm mb-2">
                            <span className="input-group-text pci-socials-edit pci-socials-linkedin"><i className="bi bi-linkedin"/></span>
                            <input
                                className="form-control" 
                                aria-label="With textarea"
                                placeholder="LinkedIn"
                                value={linkedInEntry}
                                onChange={(e) => setLinkedInEntry(e.target.value)}
                            />
                        </div>
                        <div className="input-group input-group-sm mb-2">
                            <span className="input-group-text pci-socials-facebook"><i className="bi bi-facebook"/></span>
                            <input
                                className="form-control" 
                                aria-label="With textarea"
                                placeholder="Facebook"
                                value={facebookEntry}
                                onChange={(e) => setFacebookEntry(e.target.value)}
                            />
                        </div>
                        <div className="input-group input-group-sm mb-2">
                            <span className="input-group-text pci-socials-youtube"><i className="bi bi-youtube"/></span>
                            <input
                                className="form-control" 
                                aria-label="With textarea"
                                placeholder="YouTube"
                                value={youtubeEntry}
                                onChange={(e) => setYouTubeEntry(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input-group input-group-sm mb-2">
                            <span className="input-group-text pci-socials-threads"><i className="bi bi-threads"/></span>
                            <input
                                className="form-control" 
                                aria-label="With textarea"
                                placeholder="Threads"
                                value={threadsEntry}
                                onChange={(e) => setThreadsEntry(e.target.value)}
                            />
                        </div>
                        <div className="input-group input-group-sm mb-2">
                            <span className="input-group-text pci-socials-instagram"><i className="bi bi-instagram"/></span>
                            <input
                                className="form-control" 
                                aria-label="With textarea"
                                placeholder="Instagram"
                                value={instagramEntry}
                                onChange={(e) => setInstagramEntry(e.target.value)}
                            />
                        </div>
                        <div className="input-group input-group-sm mb-2">
                            <span className="input-group-text pci-socials-tiktok"><i className="bi bi-tiktok"/></span>
                            <input
                                className="form-control" 
                                aria-label="With textarea"
                                placeholder="TikTok"
                                value={tiktokEntry}
                                onChange={(e) => setTikTokEntry(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    {missingInfo && (
                        <span className="missing-info-message">Please provide entries for the following required fields: {missingFields.join(", ")}</span>
                    )}
                    
                </div>
                <br/>
                <div className="row">
                    <div className="col-md-9"></div>
                    <div className="col-md-3">
                        <button 
                        type="button" 
                        className="btn create-next-button"
                        alt="Button to save changes to profile info and move to form to enter current job information"
                        onClick={handleNextClick}
                        >Next<i className="bi bi-arrow-right ms-1"/></button>
                    </div>
                </div>
                
            </div>
           
        </>
    )
}