import React, { useState, useEffect } from "react";

// Section for Utilities and Context
import "../pages/publicsquare.css";
import API from "../utils/API";

// Section for Components
import HeadlineCard from "../components/HeadlineCard";

export default function HeadlineFeed() {
    const [orderedNews, setOrderedNews] = useState([]);

    useEffect(() => {
        // Function to fetch news from the backend
        const fetchNews = async () => {
            try {
                const response = await API.getNews(); 
                const newsData = response.data;
                
                // Shuffle and set the news
                const shuffledNews = [...newsData].sort(() => Math.random() - 0.5);
                setOrderedNews(shuffledNews);
            } catch (error) {
                console.error("Error fetching news data:", error);
            }
        };

        fetchNews(); 
    }, []);
     

    const handlePostDate = (newsArray) => {
        if (!Array.isArray(newsArray) || !newsArray.length) return ''; // Check if it's an array and not empty
    
        const mostRecentCreatedAt = getMostRecentPostDate(newsArray);
    
        if (!mostRecentCreatedAt) return ''; // Handle cases where there's no date
    
        const date = new Date(mostRecentCreatedAt);
        
        date.setHours(date.getHours() - 7); //Adjust for Pacific Time Zone
        date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
    
        // Return formatted date
        return date.toLocaleString('en-US', {
            month: 'short',  // 'Oct'
            day: '2-digit',  // '05'
            year: 'numeric', // '2024'
            hour: 'numeric', // '11'
            minute: '2-digit', // '24'
            hour12: true      // 'am' or 'pm'
        });
    };
    
    // Function to get the most recent 'createdAt' date
    const getMostRecentPostDate = (newsArray) => {
        if (!newsArray.length) return null; // Check if it's an array and not empty
    
        // Sort by 'createdAt' in descending order (most recent first)
        const sortedNews = newsArray.slice().sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    
        // Return the 'createdAt' of the most recent news post
        return sortedNews[0].createdAt;
    };
    
    return (
        <>
            {/* Headlines column title */}
            {/* Headline dropdown list */}
            <div className="news-feed" >
                {/* news headline feed */}
                <div className="headlines-div">
                    <h6 className="headlines-title display-6">Headlines</h6>
                </div>
                

                {/* HIDDEN INFO POPOVER FOR HEADLINES SEARCH - DO NOT DELETE */}
                {/* <div  className="btn popover-div headlines-popover" data-bs-toggle="popover" title="Headlines Search" data-bs-trigger="hover" data-bs-content="Headlines Search allows you to query the latest news without leaving 'elloGovna. Everyday, we compile the hottest searches from other 'elloGovna users and populate the topic dropdown so you're not left scrambling."><i className="bi bi-info-circle"></i></div> */}

                {/* HIDDEN SEARCH FOR HEADLINES - DO NOT DELETE */}
                {/* <div className="input-group input-group-sm mb-3 headlines-search-div">
                    <input type="text" className="form-control headlines-search" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" placeholder="Search News"/>
                </div> */}

                {/* THIS IS A DROPDOWN FOR TOP NEWS TOPICS - DO NOT DELETE */}
                {/* <div className="btn-group dropdown-center headlines-dropdown">
                    <button className="btn btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">Top News Searches</button>
                    <ul className="dropdown-menu">
                        <li><a className="dropdown-item" href="#">California Legislature</a></li>
                        <li><a className="dropdown-item" href="#">Homelessness</a></li>
                        <li><a className="dropdown-item" href="#">Budget</a></li>
                        <li><a className="dropdown-item" href="#">Proposition 98</a></li>
                        <li><a className="dropdown-item" href="#">Drug Therapy</a></li>
                        <li><a className="dropdown-item" href="#">Housing Affordability</a></li>
                        <li><a className="dropdown-item" href="#">Retail Theft</a></li>
                        <li><a className="dropdown-item" href="#">Climate Change</a></li>
                        <li><a className="dropdown-item" href="#">Single-Payer Healthcare</a></li>
                    </ul>
                </div> */}
                
                <div className="headline-feed overflow-auto" >
                <p className="headlines-updated mb-0 pt-1">
                    {orderedNews.length > 0 && (
                        <>
                            Updated: {handlePostDate(orderedNews)}
                        </>                        
                    )}
                    {orderedNews.length === 0 && (<></>)}
                    
                </p>
                    {orderedNews.map((story, index) => (
                        // Pass post data as props to UserCard component
                        <HeadlineCard 
                            key={index}
                            title={story.title}
                            snippet={story.snippet}
                            url={story.url}
                            logo={story.logo}
                            publication={story.publication}
                        />
                    ))}
                </div>
                
            </div>

        </>
    )
}