import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

// Section for Utilities and Context
import './colleaguelist.css';
import API from "../utils/API";

// Section for Components
import portrait1 from "../assets/portrait-1.png";
import portrait2 from "../assets/portrait-2.png";
import portrait3 from "../assets/portrait-3.png";
import portrait4 from "../assets/portrait-4.png";
import portrait5 from "../assets/portrait-5.png";
import portrait6 from "../assets/portrait-6.png";
import portrait7 from "../assets/portrait-7.png";

// Local Functions
const randomDefaultImage = () => {
    const defaultPicture = [portrait1, portrait2, portrait3, portrait4, portrait5, portrait6, portrait7];
    const randomIndex = Math.floor(Math.random() * defaultPicture.length);
    return defaultPicture[randomIndex];
}

// Main Function
export default function ColleagueList(props) {
    const { id } = useParams();
    const navigate = useNavigate();
    const [displayedResults, setDisplayedResults] = useState(5);
    const [colleagues, setColleagues] = useState([]);

    useEffect(() => {
        // GET SINGLE PROFILE INFO FROM DATABASE FOR PROFILE PAGE
        const fetchColleagues = async () => {        
            try {

                if (props.division_id != null) {
                    const data = {
                        division_id: props.division_id
                    }
        
                    const response = await API.getDivisionColleagues(data); 
                    // console.log("Fetched Colleague Data:", response.data);
                    setColleagues(response.data);
                } else {

                    const data = {
                        organization_id: props.organization_id
                    }
        
                    const response = await API.getOrganizationColleagues(data); 
                    // console.log("Fetched Colleague Data:", response.data);
                    setColleagues(response.data);
                    // props.reRenderProfile();
                }

    
                
            } catch (error) {
                console.log("Error fetching single profile:", error);
            }
        };

        fetchColleagues();

    }, [props]);  


    // Convert the `id` from useParams to a number for accurate comparison
    const profileId = parseInt(id, 10);

    // Filter colleagues based on organization and index
    const displayedColleagues = colleagues.filter(colleague =>
        colleague.profile_id !== profileId
    ).slice(0, displayedResults);

    const displayedAllColleagues = colleagues.filter(colleague =>
        colleague.profile_id !== profileId
    );

    const handleNavigate = (colleague_id) => {
        navigate("/profile/" + colleague_id);
    }

    const handleColleagueList = (colleagues, viewAll) => {
        if(colleagues.length > 0 && !viewAll) {
            return (
                colleagues.sort(() => Math.random() - 0.5).map((colleague) => (
                    <li key={colleague.profile_id} className="list-group-item colleague-item" onClick={() => handleNavigate(colleague.profile_id)} >
                        <a className="row colleague-item-row"> 
                            <div className="col-2">
                                <img src={colleague.ProfileInfo.profile_picture || randomDefaultImage()} className="colleague-picture" alt={`${colleague.ProfileInfo.first_name} ${colleague.ProfileInfo.last_name} picture`} />
                            </div>
                            <div className="col-8">
                                <p className="colleague-name-title"><b>{colleague.ProfileInfo.first_name} {colleague.ProfileInfo.last_name}</b>
                                    <br /><i>{colleague.title}</i></p>
                            </div>
                        </a>
                    </li>
                ))
            )
        }

        if(colleagues.length > 0 && viewAll) {
            return (
                colleagues.map((colleague) => (
                    <li key={colleague.profile_id} className="list-group-item colleague-item" data-bs-dismiss="modal"  onClick={() => handleNavigate(colleague.profile_id)} >
                        <a className="row colleague-item-row"> 
                            <div className="col-2">
                                <img src={colleague.ProfileInfo.profile_picture || randomDefaultImage()} className="colleague-picture" alt={`${colleague.ProfileInfo.first_name} ${colleague.ProfileInfo.last_name} picture`} />
                            </div>
                            <div className="col-8">
                                <p className="colleague-name-title"><b>{colleague.ProfileInfo.first_name} {colleague.ProfileInfo.last_name}</b>
                                    <br /><i>{colleague.title}</i></p>
                            </div>
                        </a>
                    </li>
                ))
            )
        }

        return <div className="py-2 ms-3">No colleagues to show.</div>

    }

    return (
        <>
            <div className="card colleague-list-card">
                <div className="card-header colleague-list-header text-center display-6">Colleagues <i>at</i><br/> {props.colleague_team}</div>
                <div className="list-group list-group-flush colleague-list">
                    {handleColleagueList(displayedColleagues, false)}             
                     <li className="list-group-item colleague-item">
                        <a className="row colleague-open-modal" data-bs-toggle="modal" data-bs-target="#colleaguesModal">View All Colleagues</a>
                    </li>
                </div>
            </div>

            {/* Modal to edit the content of the user's bio */}
            <div className="modal fade colleagues-modal" id="colleaguesModal" tabIndex="-1" aria-labelledby="colleaguesModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header colleagues-modal-header">
                            <h5 className="modal-title colleagues-modal-title display-6">Colleagues <i>at</i> {props.colleague_team}</h5>
                        </div>
                        <div className="modal-body colleagues-modal-body">
                            <div className="list-group list-group-flush colleague-modal-list">
                                {handleColleagueList(displayedAllColleagues, true)}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn modal-close" data-bs-dismiss="modal" alt="Close modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}