import React, { useEffect } from 'react';

const PopoverInitializer = () => {
  useEffect(() => {
    // Initialize Popovers
    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
      return new window.bootstrap.Popover(popoverTriggerEl)
    });
  }, []);

  return null; // This component doesn't render anything
};

export default PopoverInitializer;
