import React, {useState, useEffect} from "react";

// Section for Utilities and Context
import "./directory.css";
import API from "../utils/API";

// Section for Components
import DirectoryCard from "../components/DirectoryCard";
import AdBox from "../components/AdBox";
// import BackToTopButton from "../components/BackToTop";


// MAIN FUNCTION
export default function Directory() {
    // List of states used for Directory page
    const [searchQuery, setSearchQuery] = useState("");
    const [filterSelect, setFilterSelect] = useState("");
    const [issueSelect, setIssueSelect] = useState(undefined);
    const [displayedResults, setDisplayedResults] = useState(30);
    const [cardInfo, setCardInfo] = useState([]);
    const [issuesInfo, setIssuesInfo] = useState([]);
    const [loading, setLoading] = useState(false); 

    // Infinite scroll handler
    const handleScroll = () => {
        const lastCard = document.querySelector(".directory-cards > :last-child");

        if (lastCard) {
            const lastCardRect = lastCard.getBoundingClientRect();

            if (lastCardRect.bottom <= window.innerHeight) {
                setDisplayedResults((prev) => prev + 30);
            }
        }
    };
      
    // Add an event listener in the useEffect hook
    useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
        window.removeEventListener("scroll", handleScroll);
    };
    }, []);


    useEffect(() => {
        const fetchDirectoryProfiles = async () => {
            setLoading(true);
            const data = await API.getDirectoryProfiles();
            
            data.data.forEach(profile => {
                profile.JobExperiences = profile.JobExperiences.filter(job => job.current_job === true)
            })

            setCardInfo(data.data);
            setLoading(false);
        };

        const fetchIssueAreas = async () => {
            const data = await API.getIssueAreas();
            setIssuesInfo(data.data.sort((a, b) => a.issue_name.localeCompare(b.issue_name))); 
        };

        fetchDirectoryProfiles();
        fetchIssueAreas();
    }, []);

    // Function to handle clearing filters and search
    const handleResetFilters = () => {
        setFilterSelect("");
        setSearchQuery("");
        setIssueSelect(undefined);
    };

    // Filtering through the imported results. Make sure to update source once connecting to API / database
    const searchUsers = cardInfo.filter(user => {
        const searchParts = searchQuery.toLowerCase().split(" ");
    
        const matchesSearch = searchParts.every(part =>
            user.first_name.toLowerCase().includes(part) ||
            user.last_name.toLowerCase().includes(part) ||
            user.JobExperiences[0]?.title.toLowerCase().includes(part) ||
            user.JobExperiences[0]?.Organization.organization_name.toLowerCase().includes(part) ||
            user.JobExperiences[0]?.Division?.division_name.toLowerCase().includes(part)
        );

        const matchesFilter = filterSelect === "" || user.JobExperiences[0]?.Organization.group_type === filterSelect || 
            (Array.isArray(user.JobExperiences[0]?.Organization.group_type) && 
            user.JobExperiences[0]?.Organization.group_type.some(group_type => filterSelect.includes(group_type))) ||
            (filterSelect === "Electeds" && (
                user.JobExperiences[0]?.title === "Senator" || 
                user.JobExperiences[0]?.title === "Assemblymember" ||
                user.JobExperiences[0]?.title === "Governor" ||
                user.JobExperiences[0]?.title === "Lt. Governor" ||
                user.JobExperiences[0]?.title === "State Treasurer" ||
                user.JobExperiences[0]?.title === "Insurance Commissioner" ||
                user.JobExperiences[0]?.title === "State Controller" 
            ));

        const issueSelectAsInt = parseInt(issueSelect, 10);
        let matchesIssue = issueSelect === undefined || user.UserIssues.some(issue => issue.issue_id === issueSelectAsInt);
        

        return matchesSearch && matchesFilter && matchesIssue;
    });
    
    return (
        <>
            <div className="row directory-row">
                    <div className="dir-header"/> 
                <div className="col-xl-2 col-lg-3 col-md-3 col-sm-12 ps-md-0 me-md-3 directory-left-column">
                    <div className="form-floating directory-search-div z-3 ">
                        <input className="form-control border-0 border-bottom directory-search" id="search-directory" type="text" placeholder="" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                        <label className="directory-search-label" htmlFor="search-directory">Search</label>
                    </div>
                    
                    <br/>
                    <div className="row directory-filter-div">
                        <button className={`directory-filter-button ${filterSelect === "Associations" ? "selected" : ""}`} onClick={() => setFilterSelect("Associations")}>Associations</button>
                        <button className={`directory-filter-button ${filterSelect === "Electeds" ? "selected" : ""}`} onClick={() => setFilterSelect("Electeds")}>Elected Officials</button>
                        <button className={`directory-filter-button ${filterSelect === "Agencies" ? "selected" : ""}`} onClick={() => setFilterSelect("Agencies")}>Exec. Agencies</button>
                        <button className={`directory-filter-button ${filterSelect === "Governor Office" ? "selected" : ""}`} onClick={() => setFilterSelect("Governor Office")}>Governor's Office</button>
                        <button className={`directory-filter-button ${filterSelect === "Legislative" ? "selected" : ""}`} onClick={() => setFilterSelect("Legislative")}>Legislature</button>
                        <button className={`directory-filter-button ${filterSelect === "Lobbyists" ? "selected" : ""}`} onClick={() => setFilterSelect("Lobbyists")}>Lobby Firms</button>
                        <button className={`directory-filter-button ${filterSelect === "Unions" ? "selected" : ""}`} onClick={() => setFilterSelect("Unions")}>Unions</button>
                        <button className={`directory-filter-button ${filterSelect === "Other" ? "selected" : ""}`} onClick={() => setFilterSelect("Other")}>Other</button>
                        {/* Place Issues drop down here */}
                        <select className="form-select directory-filter-select" value={issueSelect !== undefined ? issueSelect : ""} onChange={(e) => setIssueSelect(e.target.value)}>
                            <option value="" disabled>Select Issue Area</option>
                            {issuesInfo.map(issue => (
                                <option key={issue.issue_id} value={issue.issue_id}>{issue.issue_name}</option>
                            ))}
                        </select>
                        <button className={`clear-filter-button ${filterSelect === "" ? "hidden" : "" }`} onClick={handleResetFilters}>Reset Filters</button>
                        {/* <div className="mt-3">
                            <AdBox />
                        </div> */}
                    </div>
                </div>
                <div className="col-lg-7 col-md-8 col-sm-12 px-md-1">
                    <div className="directory-body">
                        <div className="row directory-cards">
                            {searchUsers.slice(0, displayedResults).map((user, index) => (
                                // Pass user data as props to UserCard component
                                <DirectoryCard 
                                    key={user.profile_id} 
                                    profile_id={user.profile_id} 
                                    firstName={user.first_name}
                                    lastName={user.last_name}
                                    title={user.JobExperiences[0]?.title}
                                    organization={user.JobExperiences[0]?.Organization.organization_name}
                                    division={user.JobExperiences[0]?.Division?.division_name || ""}
                                    profile_picture={user.profile_picture}
                                    group_type={user.JobExperiences[0]?.Organization.group_type}
                                />
                            ))}
                            
                        </div>
                        {loading && cardInfo.length === 0 && (
                            <div className="d-flex position-absolute top-50 start-50 translate-middle ">
                                <div className="spinner-border loading-spinner" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
                {/* <BackToTopButton /> */}
            
           
        </>
    )
}