import React, {useState, useEffect} from "react";
import API from "../utils/API";

// Section for Utilities and Context
import "./landing.css";
// import data from "../utils/test.json";

// Section for Components
import landingPic from "../assets/ornate-interior.jpg";
import LoginModal from "../components/LoginModal";
import RegisterModal from "../components/RegisterModal";


// MAIN FUNCTION
export default function Landing() {
    return (
        <>
            <img className="position-absolute start-50 top-50 translate-middle landing-image sticky" src={landingPic}></img>
            <div className="position-absolute start-50 top-50 translate-middle welcome-div">
                    <h1 className="display-1 landing-welcome-title"><i>'ello</i>, Govna!</h1>
                    {/* <h1 className="display-1 landing-welcome"><strong><i>'ello</i>Govna</strong></h1> */}
                    <hr/>
                    <p className="welcome-message"><strong><i>'ello</i>Govna</strong> is the new directory for legislative and regulatory affairs professionals working with the State of California to stay connected and informed about news and your colleagues to keep government working.<br/><br/>Here you'll be able to scroll through the day's headlines, find the staff working on the issue you need amendments on, post announcements, and even comment on your colleagues' posts.<br/><br/>If you find you're not able to register and work for or do business with the State of California, please reach out to <a href="mailto:info@ellogovna.com">info@ellogovna.com</a> to request access.</p>
                    {/* <br/> */}
                    <hr/>
                    <h1 className="display-6 landing-login-register">Please <span className="landing-links" data-bs-toggle="modal" data-bs-target="#loginModal">login</span> or <a href="" className="landing-links" data-bs-toggle="modal" data-bs-target="#registerModal">register</a> a new account!</h1>
                    <br/>
                    <p className="welcome-psst mb-0">*psst...we love a friendly little <i>'ello</i> but sometimes we just call it <i>"Govna"</i>, too.</p>
            </div>

            <LoginModal/>
            <RegisterModal/>
        </>
    )
}