import React, { useEffect, useReducer } from "react";

// Reducer function to manage auth state
let reducer = (auth, newAuth) => {
  if (newAuth === null) {
    localStorage.removeItem("auth");
    return initialAuth;
  }
  return { ...auth, ...newAuth };
};

// Initial auth state
const initialAuth = {
  token: null,
  isAuthenticated: null,
  loading: null,
  user: null,
};

// Create AuthContext
const AuthContext = React.createContext();

const localAuth = JSON.parse(localStorage.getItem("auth")) || initialAuth;

function AuthProvider(props) {
  const [auth, setAuth] = useReducer(reducer, localAuth);

  useEffect(() => {
    if (auth){
      localStorage.setItem("auth", JSON.stringify(auth));
    }
  }, [auth]);

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {props.children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
