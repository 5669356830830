import React, { useState, useContext, useRef, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";

// Section for Utilities and Context
import "./biobox.css";
import API from "../utils/API";

// Section for Components

// Limit display of bio to 550 characters max
const BioBody = ({ body }) => {
    const MAX_CHARACTERS = 550;

    if (body === null) {
        return (
            <p className="card-text bio-box-text">No biography available.</p>
          );
    }
  
    // Logic to truncate body to maximum characters
    const truncatedBody = body.length > MAX_CHARACTERS ? `${body.substring(0, MAX_CHARACTERS)}...` : body;
  
    return (
      <p className="card-text bio-box-text">{truncatedBody}</p>
    );
};

export default function CreateMiscForm(props) {
    const navigate = useNavigate();
    const [newBio, setNewBio] = useState();
    const [issues, setIssues] = useState([]);
    const [issueList, setIssueList] = useState([]);
    const [issueSearchTerm, setIssueSearchTerm] = useState("");
    const [issueSelected, setIssueSelected] = useState(false);
    const [selectedIssueId, setSelectedIssueId] = useState(null);
    const [highlightedIssueIndex, setHighlightedIssueIndex] = useState(-1);
    const [redundantIssue, setRedundantIssue] = useState(false);
    const [missingInfo, setMissingInfo] = useState(false);
    const [missingFields, setMissingFields] = useState([]); 
    const dropdownLength = 5;
    const dropdownRef = useRef(null);

    // console.log("issues list: ", issueList)

    // API IMPORT AND HANDLERS OF ISSUE AREA DATA
    useEffect(() => {
        const fetchIssueAreas = async () => {
            try {
                const response = await API.getIssueAreas();
                const existingIssues = new Set(issueList.map(issue => issue.toLowerCase()));
                const filteredIssues = response.data.filter(issue =>
                    issue.issue_name.toLowerCase().includes(issueSearchTerm.toLowerCase()) 
                    && !existingIssues.has(issue.issue_name.toLowerCase())
                );
                // setIssues(response.slice(0,5));
                setIssues(filteredIssues.slice(0,5));
            } catch (error) {
                console.error("Error fetching organizations:", error);
            }
        };

        if (issueSearchTerm !== "") {
            fetchIssueAreas();
        } else {
            setIssues([]);
        }

        // setProfileId(props.issues[0].profile_id);

    }, [issueSearchTerm, issueSelected]);


    const sortedIssues = issueList.sort((a, b) => {
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
    });

    const checkCurrentIssues = (searchTerm) => {
        for (let i = 0; i < issueList.length; i++) {
            if (searchTerm.toLowerCase() === issueList[i].toLowerCase()) {
                setRedundantIssue(true);
                setIssueSelected(false);
                return; 
            }
        }
        setRedundantIssue(false); // Reset if no match is found
    };

    // HANDLER FOR CLEARED ORGANIZATION INPUT
    const handleIssueInputChange = (e) => {
        const searchTerm = e.target.value;
        setIssueSearchTerm(searchTerm);
        checkCurrentIssues(searchTerm);

        if (issueSelected) {
            setIssueSelected(false);
        }
    };

    // HANDLER FOR NAVIGATING DROPDOWN MENU
    const handleIssueKeyDown = (e) => {
        if (e.key === "ArrowUp") {
            e.preventDefault();
            setHighlightedIssueIndex(prevIndex => Math.max(prevIndex - 1, 0));
        } else if (e.key === "ArrowDown") {
            e.preventDefault();
            setHighlightedIssueIndex(prevIndex => Math.min(prevIndex + 1, dropdownLength - 1));
        } else if (e.key === "Enter" && highlightedIssueIndex !== -1) {
            handleIssueClick(
                issues[highlightedIssueIndex].issue_name,
                issues[highlightedIssueIndex].issue_id
            );
        }
    };
    
    const handleIssueClick = (issueName, issueId) => {
        setIssueSearchTerm(issueName);
        setSelectedIssueId(issueId);
        setIssueSelected(true);
        setHighlightedIssueIndex(-1);
    };

    // DROPDOWN HEIGHT
    const calculateDropdownHeight = () => {
        const optionHeight = 35; 
        const maxHeight = optionHeight * dropdownLength;
        return `${maxHeight}px`;
    };

    // HANDLER FOR ADDING JOB EXPERIENCE TO DATABASE
    const addIssueArea = async () => {
        try {
            if (redundantIssue) {
                console.log("Cannot add a duplicate issue.")
            } else {    
                const data = {
                    profile_id: props.profileId
                }

                if (selectedIssueId) {
                    data.issue_id = selectedIssueId;
                } else {
                    const issueData = {
                        issue_name: issueSearchTerm
                    }

                    const orgResponse = await API.addIssueArea(issueData);
                    console.log("IssueArea added successfully:", orgResponse.data);

                    const fetchResponse = await API.getIssueAreas()

                    for(let i = 0; i < fetchResponse.data.length; i++){
                        // console.log("in org for loop")
                        if(fetchResponse.data[i].issue_name === issueSearchTerm) {
                            console.log("for loop if statement")
                            setSelectedIssueId(fetchResponse.data[i].issue_id);
                            data.issue_id = fetchResponse.data[i].issue_id;
                            break;
                        }
                    }
                }

                const response = await API.addUserIssue(data);
                console.log("User issue added successfully:", response.data);
                setIssueList(prevIssueList => [...prevIssueList, issueSearchTerm]);
                setIssueSearchTerm("");
            }

        } catch(error) {
           console.error("Server request failed: ",error);
        }
        
    };

    const handleSave = () => {        
        if (newBio) {
            saveProfileCreation(); 
        } else {
            setMissingInfo(true);
        }
    };

    // HANDLER FOR ADDING JOB EXPERIENCE TO DATABASE
    const saveProfileCreation = async () => {

        try {
            const data = {
                profile_id: props.profileId,
                user_bio: newBio
            }
            
            const response = await API.updateBiography(data);
            console.log("Biography updated successfully:", response.data);
            resetStates();
            navigate("/public-square");

        } catch(error) {
           console.error("Server request failed: ",error);
        }        
    };

    const resetStates = () => {
        setNewBio();
        setIssues([]);
        setIssueList([]);
        setIssueSearchTerm("");
        setIssueSelected(false);
        setSelectedIssueId(null);   
    }

    return (
        <>              
            <div className="m-0">
                <div className="bio-box-header text-center display-6">Please add a short biography</div>                
                    <div className="mt-3">
                        <textarea
                            className="form-control bio-textarea px-2" 
                            aria-label="With textarea"
                            placeholder="Share a short bio about yourself"
                            value={newBio} 
                            onChange={(e) => {setNewBio(e.target.value)}}
                        />
                    </div>                        
            </div>
            <div className="m-0">
                <div className="bio-box-header text-center display-6 mt-4 mb-3">Current Issue Areas</div>
                <div className="input-group input-group-sm mb-3">
                    <input type="text" 
                        className="form-control rounded-end " 
                        aria-label="Sizing example input" 
                        aria-describedby="inputGroup-sizing-sm"
                        placeholder="Enter issue areas you're working on..."
                        value={issueSearchTerm}
                        onChange={handleIssueInputChange}
                        onKeyDown={handleIssueKeyDown}
                    />
                    <span>
                        <i className="bi bi-plus-circle add-issue-icon ms-2" 
                            onClick={addIssueArea}
                        />
                    </span>
                </div>
                {!issueSelected && issues.length > 0 && (
                    <select className="form-select" size="5"
                            style={{ maxHeight: calculateDropdownHeight(), overflowY: "auto" }}
                            ref={dropdownRef} >
                        {issues.map((issue, index) => (
                            <option key={issue.issue_id} 
                            className={`dropdown-item add-job-option ${index === highlightedIssueIndex ? "active-option" : ""}`}
                            onClick={() => handleIssueClick(issue.issue_name, issue.issue_id)}>{issue.issue_name}<br/></option>
                        ))}
                    </select>
                )}
                {redundantIssue && (
                    <div className="redundant-issue-message mb-2 pt-0">You already added this issue.</div>
                )}
                
                <div id="issues-div" className="row">            
                    {(issueList.length > 0) && (
                        sortedIssues.map((issue) => (
                            <div className="profile-single-issue">{issue}</div>
                        ))
                    )}
                </div>

                 
            </div>
            <div className="row">
                {missingInfo && (
                    <span className="missing-info-message">Please provide a brief discription about who you are and your expertise to finalize your profile.</span>
                )}
                
            </div>
            <br/>
            <br/>
            <div className="row">
                <div className="col-md-9"></div>
                <div className="col-md-3">
                    <button 
                        type="button" 
                        className="btn create-submit-button"
                        alt="Button to save changes to profile info"
                        onClick={handleSave}
                    >Save <i className="bi bi-check ms-1"/></button>
                </div>
            </div>
        </>
    )
}